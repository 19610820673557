import React, { useState, ChangeEvent, useContext } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import DOMPurify from "dompurify";
import SummaryAPI from "../../src/common/index";
import { useNavigate } from "react-router-dom";
import { customToast } from "../common/helperFunction";
import Context from "../context";

// Utility function to sanitize and validate inputs
const sanitizeInput = (input: string) => DOMPurify.sanitize(input);

const validateSignup = (
  firstName: string,
  lastName: string,
  email: string,
  mobile: string,
  password: string
) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^[0-9]{10}$/; // Adjust this regex according to your mobile number format requirements
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;

  if (!firstName || !lastName || !email || !mobile || !password)
    return "All fields are required.";
  if (!emailRegex.test(email)) return "Invalid email format.";
  if (!phoneRegex.test(mobile))
    return "Invalid mobile number format. Must be 10 digits.";
  if (!passwordRegex.test(password))
    return "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, and one special character.";
  return null;
};

const useInputHandler = (initialValue: string) => {
  const [value, setValue] = useState<string>(initialValue);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(sanitizeInput(e.target.value));
  };

  return [value, handleChange] as const;
};

const SignUp: React.FC = () => {
  const [firstName, handleFirstNameChange] = useInputHandler("");
  const [lastName, handleLastNameChange] = useInputHandler("");
  const [email, handleEmailChange] = useInputHandler("");
  const [mobile, handleMobileChange] = useInputHandler("");
  const [password, handlePasswordChange] = useInputHandler("");
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

  const [otp, setOtp] = useState<string>("");
  const [otpSent, setOtpSent] = useState<boolean>(false);
  const [isOtpVerified, setIsOtpVerified] = useState<boolean>(false);

  const websiteName = "SABR";
  const context = useContext(Context);
  const setIsLoading = context?.setIsLoading;

  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleOtpVerification = async () => {
    if (setIsLoading) setIsLoading(true);
    try {
      const verifyResponse = await fetch(SummaryAPI.verifyOTP.URL, {
        method: SummaryAPI.verifyOTP.method,
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify({ email, otp }),
      });

      const verifyData = await verifyResponse.json();
      // console.log(verifyData);
      if (verifyData.success) {
        setIsOtpVerified(true);
        completeRegistration(); // Call the signup completion function
      } else {
        customToast("Invalid OTP. Please try again.", "error");
      }
    } catch {
      customToast("An error occurred during OTP verification.", "error");
    }
    if (setIsLoading) setIsLoading(false);
  };

  const completeRegistration = async () => {
    if (setIsLoading) setIsLoading(true);
    try {
      const response = await fetch(SummaryAPI.signUp.URL, {
        method: SummaryAPI.signUp.method,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          firstName,
          lastName,
          emailId: email,
          password,
          mobileNumber: mobile,
        }),
      });

      const data = await response.json();
      if (data.success) {
        customToast("Registration successful!", "success");
        navigate("/login");

        const bodyHtml = `
  <div style="font-family: Arial, sans-serif; max-width: 600px; margin: auto; padding: 20px; color: #333;">
    <h2 style="text-align: center; color: #FF6347;">🎉 Registration Success! Welcome to ${websiteName} ❤️</h2>
    <p>Dear ${firstName} ${lastName},</p>
    <p>Thank you for registering with ${websiteName}! We're thrilled to have you as part of our community.</p>
    <p style="margin-top: 20px;">
      <strong>Explore Our Collection:</strong> Click the button below to start shopping and discover our exclusive range of products.
    </p>
    <div style="text-align: center; margin: 30px 0;">
      <a 
        href="https://www.sabrcollection.com/" 
        style="padding: 10px 20px; background-color: #FF6347; color: white; text-decoration: none; border-radius: 5px; font-weight: bold;">
        Start Shopping
      </a>
    </div>
    <p>If you have any questions, feel free to reach out to our support team. We're here to help!</p>
    <p style="margin-top: 20px;">Best regards, <br/>The ${websiteName} Team</p>
  </div>
`;
        //<hr style="border: none; border-top: 1px solid #ddd; margin: 30px 0;" />
        //<p style="font-size: 12px; color: #888;">Please do not reply to this email. For any assistance, contact us at support@sabrcollection.com.</p>
        const mail = await fetch(SummaryAPI.sendmail.URL, {
          method: SummaryAPI.sendmail.method,
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            to: email,
            subject: `🎉 Registration Success! Welcome to ${websiteName} ❤️`,
            bodyText: `Welcome to ${websiteName}
                        Dear ${firstName} ${lastName}, You have successfully registered. Please click the below link for shopping https://www.sabrcollection.com/.
            `,
            bodyHtml: bodyHtml,
          }),
        });

        const mailData = await mail.json();
      } else {
        customToast("Registration failed.", "error");
      }
    } catch {
      customToast(
        "An unexpected error occurred. Please try again later.",
        "error"
      );
    }
    if (setIsLoading) setIsLoading(false);
  };

  const handleSignup = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (setIsLoading) setIsLoading(true);
    const validationError = validateSignup(
      firstName,
      lastName,
      email,
      mobile,
      password
    );
    if (validationError) {
      // setError(validationError);
      // console.log(validationError);
      customToast(validationError, "error");
      return;
    }

    try {
      // Request OTP
      const otpResponse = await fetch(SummaryAPI.generateOTP.URL, {
        method: SummaryAPI.generateOTP.method,
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify({ emailId: email }),
      });

      const otpData = await otpResponse.json();

      // console.log(otpData, "here checking");
      if (otpData.success) {
        const bodyHtml = `
        <div style="font-family: Arial, sans-serif; padding: 20px; background-color: #f4f4f4; border-radius: 8px;">
          <h2 style="color: #333;">${websiteName} - Your One-Time Use OTP</h2>
          <hr style="border: none; border-top: 1px solid #ddd; margin: 20px 0;" />
          <p style="font-size: 14px; color: #555;">
            Dear Guest,
          </p>
          <p style="font-size: 16px; color: #333; font-weight: bold;">
            Your OTP is: 
            <span style="color: #007bff; font-weight: bold; font-size: 24px;">${otpData.data.otp}</span>
          </p>
          <p style="font-size: 14px; color: #555;">
            Please key in your OTP to verify your Email ID.
          </p>
          <p style="font-size: 12px; color: #888;">
            If you did not request this OTP, please ignore this email.
          </p>
        </div>
      `;
        // <hr style="border: none; border-top: 1px solid #ddd; margin: 30px 0;" />
        //     <p style="font-size: 12px; color: #888;">
        //       Please do not reply to this email. For any assistance, contact us at
        //       <a href="mailto:support@sabrcollection.com" style="color: #007bff; text-decoration: none;">support@sabrcollection.com</a>.
        //     </p>

        const mail = await fetch(SummaryAPI.sendmail.URL, {
          method: SummaryAPI.sendmail.method,
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            to: email,
            subject: `${websiteName} - Your one time use OTP`,
            bodyText: `Dear Guest,
${otpData.data.otp} is Your OTP for ${websiteName}. Please key in your OTP to verify your Email ID.`,
            bodyHtml: bodyHtml,
          }),
        });

        const mailData = await mail.json();
        setOtpSent(true);
        customToast("OTP sent to your email!", "success");
      } else {
        customToast(otpData.message || "Failed to send OTP.", "error");
      }
    } catch {
      customToast("An error occurred while sending OTP.", "error");
    }
    if (setIsLoading) setIsLoading(false);
  };

  return (
    <div className="flex justify-center items-center min-h-screen">
      <div className="p-8 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold text-center mb-6">Sign Up</h2>
        <form className="space-y-4" onSubmit={handleSignup}>
          <div className="md:flex md:space-x-4">
            <div className="w-full md:w-1/2">
              <label htmlFor="firstName" className="block text-sm font-medium">
                First Name
              </label>
              <input
                type="text"
                id="firstName"
                className="bg-black mt-1 block w-full p-3 border placeholder-customGold border-customGold rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-customGold focus:border-customGold"
                value={firstName}
                onChange={handleFirstNameChange}
                placeholder="Enter your first name"
              />
            </div>
            <div className="w-full md:w-1/2 mt-4 md:mt-0">
              <label htmlFor="lastName" className="block text-sm font-medium">
                Last Name
              </label>
              <input
                type="text"
                id="lastName"
                className="bg-black mt-1 block w-full p-3 border placeholder-customGold border-customGold rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-customGold focus:border-customGold"
                value={lastName}
                onChange={handleLastNameChange}
                placeholder="Enter your last name"
              />
            </div>
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium">
              Email
            </label>
            <input
              type="email"
              id="email"
              className="bg-black mt-1 block w-full p-3 border placeholder-customGold border-customGold rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-customGold focus:border-customGold"
              value={email}
              onChange={handleEmailChange}
              placeholder="Enter your email"
            />
          </div>
          <div>
            <label htmlFor="mobile" className="block text-sm font-medium">
              Mobile Number
            </label>
            <input
              type="tel"
              id="mobile"
              className="bg-black mt-1 block w-full p-3 border placeholder-customGold border-customGold rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-customGold focus:border-customGold"
              value={mobile}
              onChange={handleMobileChange}
              placeholder="Enter your mobile number"
            />
          </div>
          <div className="relative">
            <label htmlFor="password" className="block text-sm font-medium">
              Password
            </label>
            <input
              type={passwordVisible ? "text" : "password"}
              id="password"
              className="bg-black mt-1 block w-full p-3 border placeholder-customGold border-customGold rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-customGold focus:border-customGold"
              value={password}
              onChange={handlePasswordChange}
              placeholder="Enter your password"
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute top-10 right-0 pr-3 flex items-center text-customGold"
            >
              {passwordVisible ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          <div>
            {!otpSent && (
              <button
                type="submit"
                className="w-full py-3 px-4 bg-black text-customGold rounded-md border-2 border-customGold hover:bg-customGoldHover hover:text-black hover:border-black"
              >
                Send OTP
              </button>
            )}
          </div>
        </form>

        {otpSent && !isOtpVerified && (
          <div>
            <label htmlFor="otp" className="block text-sm font-medium">
              Enter OTP
            </label>
            <input
              type="text"
              id="otp"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              placeholder="Enter OTP"
              className="w-full p-2 border border-gray-300 rounded"
            />
            <button
              onClick={handleOtpVerification}
              className="w-full py-2 mt-4 bg-customGold text-white rounded"
            >
              Verify OTP
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SignUp;
