import React, { useState, ChangeEvent, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import SummaryAPI from "../common";
import Context from "../context";
import { customToast } from "../common/helperFunction";
import DOMPurify from "dompurify";

const Login: React.FC = () => {
  // Utility function to sanitize and validate inputs
  const sanitizeInput = (input: string) => DOMPurify.sanitize(input);

  const context = useContext(Context);
  const setIsLoading = context?.setIsLoading;

  const validateInput = (emailOrPhone: string, password: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^[0-9]{10}$/;
    if (!emailOrPhone || !password) return "Both fields are required.";
    if (!emailRegex.test(emailOrPhone) && !phoneRegex.test(emailOrPhone))
      return "Invalid email or phone number.";
    if (password.length < 6)
      return "Password must be at least 6 characters long.";
    return null;
  };

  const useInputHandler = (initialValue: string) => {
    const [value, setValue] = useState<string>(initialValue);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      setValue(sanitizeInput(e.target.value));
    };

    return [value, handleChange] as const;
  };

  const [input, handleInputChange] = useInputHandler("");
  const [password, handlePasswordChange] = useInputHandler("");
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const navigate = useNavigate();
  const userDetails = useContext(Context);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (setIsLoading) setIsLoading(true);
    const validationError = validateInput(input, password);
    if (validationError) {
      // setError(validationError);
      customToast(validationError, "error");
      return;
    }

    try {
      const dataResponse = await fetch(SummaryAPI.signIn.URL, {
        method: SummaryAPI.signIn.method,
        credentials: "include",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ emailId: input, password }),
      });

      const data = await dataResponse.json();

      if (data.success) {
        customToast(data.message, "success");
        // console.log("generalContext ---> ", data.data);
        navigate("/");
        // console.log("userDetails: ", userDetails);
        if (userDetails) {
          userDetails.fetchUserDetails();
          userDetails.fetchUserAddToCart();
        }
      } else if (data.error) {
        // console.log("error in login");
        customToast(data.message, "error");
      }
    } catch (error) {
      customToast("An error occurred. Please try again later.", "error");
    }
    if (setIsLoading) setIsLoading(false);
  };

  return (
    <div className="flex justify-center items-center min-h-screen">
      <div className="p-8 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold text-center mb-4">Login</h2>
        <form className="space-y-6" onSubmit={handleLogin}>
          {error && <p className="text-red-500 text-center">{error}</p>}
          <div>
            <label
              htmlFor="input"
              className="block text-sm font-medium text-customGold"
            >
              Email Id
            </label>
            <input
              type="text"
              id="input"
              className="mt-1 block w-full p-3 border bg-black border-customGold placeholder-customGold rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-customGold focus:border-customGold"
              value={input}
              onChange={handleInputChange}
              placeholder="Enter email-id"
            />
          </div>
          <div className="relative">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-customGold"
            >
              Password
            </label>
            <input
              type={isPasswordVisible ? "text" : "password"}
              id="password"
              className="bg-black mt-1 block w-full p-3 border placeholder-customGold border-customGold rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-customGold focus:border-customGold"
              value={password}
              onChange={handlePasswordChange}
              placeholder="Enter your password"
            />
            <div
              className="absolute top-10 right-0 pr-3 flex items-center cursor-pointer"
              onClick={togglePasswordVisibility}
            >
              {isPasswordVisible ? <FaEyeSlash /> : <FaEye />}
            </div>
          </div>
          <div className="flex items-center justify-between">
            <Link
              to="/forgotPassword"
              className="text-sm text-customGold hover:underline block w-fit ml-auto"
            >
              Forgot password?
            </Link>
          </div>
          <div>
            <button
              type="submit"
              className="w-full py-3 px-4 bg-black text-customGold rounded-md border-2 border-customGold hover:bg-customGoldHover hover:text-black hover:border-black"
            >
              Login
            </button>
          </div>
        </form>
        <p className="mt-6 text-center text-sm text-customGold">
          Don't have an account?{" "}
          <Link to="/signUp" className="text-customGold hover:underline">
            Register
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Login;
