import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import SummaryAPI from "../common";
import { customToast } from "../common/helperFunction";
import Context from "../context";

const ResetPassword = () => {
  const { id, token } = useParams();
  const navigate = useNavigate();
  const [isValid, setIsValid] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const context = useContext(Context);
  const setIsLoading = context?.setIsLoading;

  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;

  useEffect(() => {
    const controller = new AbortController();
    const verifyToken = async () => {
      if (setIsLoading) setIsLoading(true);
      try {
        const response = await fetch(SummaryAPI.validateResetPasswordLink.URL, {
          method: SummaryAPI.validateResetPasswordLink.method,
          headers: { "content-type": "application/json" },
          credentials: "include",
          body: JSON.stringify({ id, token }),
          signal: controller.signal,
        });

        const data = await response.json();
        if (data.success) {
          setIsValid(true);
        } else {
          customToast("Invalid or expired password reset link.", "error");
        }
      } catch (error: any) {
        if (error.name !== "AbortError") {
          customToast("Invalid or expired password reset link.", "error");
        }
      }
      if (setIsLoading) setIsLoading(false);
    };

    verifyToken();

    // Cleanup function to abort the fetch request on component unmount
    return () => {
      controller.abort();
    };
  }, [id, token]);

  const validatePassword = () => {
    if (newPassword !== confirmPassword) return "Passwords do not match.";
    if (!passwordRegex.test(newPassword))
      return "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, and one special character.";
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const validationError = validatePassword();
    if (setIsLoading) setIsLoading(true);
    if (validationError) {
      customToast(validationError, "error");
      return;
    }

    try {
      const response = await fetch(SummaryAPI.resetPassword.URL, {
        method: SummaryAPI.resetPassword.method,
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ id, token, newPassword }),
      });

      const responseData = await response.json();
      if (responseData.success) {
        customToast(
          "Password successfully reset! Redirecting to login...",
          "success"
        );
        setTimeout(() => navigate("/login"), 3000);
      } else {
        customToast("Failed to reset password.", "error");
      }
    } catch (error) {
      customToast("Failed to reset password.", "error");
    }
    if (setIsLoading) setIsLoading(false);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4">
      <div className="shadow-lg rounded-lg p-6 w-full max-w-md border border-customGold">
        <h2 className="text-2xl font-semibold mb-6 text-center">
          Reset Your Password
        </h2>
        {isValid ? (
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-customGold">
                New Password
              </label>
              <input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="bg-black mt-1 block w-full px-3 py-2 border border-customGold rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-customGold focus:border-customGold"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-customGold">
                Confirm Password
              </label>
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="bg-black mt-1 block w-full px-3 py-2 border border-customGold rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-customGold focus:border-customGold"
                required
              />
            </div>
            <button
              type="submit"
              className="w-full bg-black text-customGold py-2 rounded-md border-2 border-customGold hover:bg-customGoldHover hover:text-black hover:border-black transition duration-200"
            >
              Reset Password
            </button>
          </form>
        ) : (
          <p className="text-center text-customGold">Link expired</p>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
