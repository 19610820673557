import React, { useState, useEffect, useContext } from "react";
import Slider from "react-slick";
import { sliderProperties } from "../interface/componentInterface";
import SummaryAPI from "../common";
import { customToast } from "../common/helperFunction";
import Context from "../context";

// Import slick-carousel CSS
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NextArrow } from "./NextArrow";
import { PrevArrow } from "./PrevArrow";
import "../style/BannerProduct.css";

const BannerProduct = () => {
  const [images, setImages] = useState<sliderProperties>({
    sliderImage: [],
  });

  const context = useContext(Context);
  const setIsLoading = context?.setIsLoading;

  const fetchImage = async () => {
    try {
      const response = await fetch(SummaryAPI.fetchSliderImage.URL, {
        method: SummaryAPI.fetchSliderImage.method,
        credentials: "include",
        headers: {
          "content-type": "application/json",
        },
      });
      const responseData = await response.json();
      if (responseData.success) {
        setImages(responseData.data[0]);
      } else if (responseData.error) {
        customToast(responseData.message, "error");
      }
    } catch (error) {
      console.error("Error fetching carousel:", error);
      customToast("An error occurred while fetching the carousel.", "error");
    }
    if (setIsLoading) setIsLoading(false);
  };

  useEffect(() => {
    if (setIsLoading) setIsLoading(true);
    fetchImage();
  }, []);

  // Slider settings for react-slick
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1400,
        settings: { slidesToShow: 2, slidesToScroll: 1 },
      },
      {
        breakpoint: 900,
        settings: { slidesToShow: 2, slidesToScroll: 1 },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    appendDots: (dots: any) => (
      <div
        style={{
          padding: "10px", // Padding around the dots
          borderRadius: "0px", // No border radius
          position: "absolute", // Position fixed for easier centering
          bottom: "-45px", // Adjust as needed
          left: "50%", // Center horizontally
          transform: "translateX(-50%)", // Center alignment
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ul style={{ margin: "0px", display: "flex", gap: "8px" }}>{dots}</ul>
      </div>
    ),
    customPaging: (i: number) => (
      <div
        className="dot"
        style={{
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      />
    ),
  };

  return (
    <div className="relative w-full mx-auto pb-12">
      <Slider {...settings}>
        {images.sliderImage.map((image, index) => (
          <div className="min-w-full" key={index}>
            <div className="relative w-full aspect-w-9 aspect-h-13">
              <img
                src={image.url}
                alt={`Slide ${index + 1}`}
                className="absolute top-0 left-0 w-full h-full object-cover"
              />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default BannerProduct;
