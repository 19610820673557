import React, { useState, useEffect, useContext } from "react";
import SummaryAPI from "../common";
import { IUser } from "../interface/pageInterface";
import moment from "moment";
import { MdModeEditOutline } from "react-icons/md";
import ChangeUserRole from "../component/ChangeUserRole";
import Context from "../context";

const AllUser = () => {
  const [allUser, setAllUsers] = useState<IUser[]>([]);
  const [updateRoleOpen, setUpdateRoleOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState({
    userId: "",
    firstName: "",
    lastName: "",
    emailId: "",
    mobileNumber: "",
    role: "",
  });
  const context = useContext(Context);
  const setIsLoading = context?.setIsLoading;

  const fetchAllUser = async () => {
    const fetchData = await fetch(SummaryAPI.allUser.URL, {
      method: SummaryAPI.allUser.method,
      credentials: "include",
    });
    const dataResponse = await fetchData.json();

    if (dataResponse.success) {
      setAllUsers(dataResponse.data);
    }
    if (setIsLoading) setIsLoading(false);
  };

  useEffect(() => {
    if (setIsLoading) setIsLoading(true);
    fetchAllUser();
  }, []);

  const thClassName =
    "px-3 py-3 border-b-2 border-gray-500 bg-gray-900 text-left text-xs sm:text-sm font-semibold whitespace-nowrap text-center";

  const tdClassName =
    "px-3 py-2 border-b border-gray-500 text-xs sm:text-sm truncate text-center";
  return (
    <div className="overflow-x-auto scrollbar-none w-full max-w-screen-xl mx-auto">
      <table className="w-full userTable">
        <thead>
          <tr>
            <th className={thClassName}>Sr.</th>
            <th className={thClassName}>Name</th>
            <th className={thClassName}>Last Name</th>
            <th className={thClassName}>Email</th>
            <th className={thClassName}>Mobile Number</th>
            <th className={thClassName}>Created Date</th>
            <th className={thClassName}>Role</th>
            <th className={thClassName}>Action</th>
          </tr>
        </thead>
        <tbody>
          {allUser.map((_user, _index) => (
            <tr key={_index}>
              <td className={tdClassName}>{_index + 1}</td>
              <td className={tdClassName}>{_user.firstName}</td>
              <td className={tdClassName}>{_user.lastName}</td>
              <td className={tdClassName}>{_user.emailId}</td>
              <td className={tdClassName}>{_user.mobileNumber}</td>
              <td className={tdClassName}>
                {moment(_user.createdAt).format("ll")}
              </td>
              <td className={tdClassName}>{_user.role}</td>
              <td className={tdClassName}>
                <button
                  className="bg-slate-700 p-2 rounded-full cursor-pointer hover:bg-slate-900"
                  onClick={() => {
                    setUpdateRoleOpen(true);
                    setSelectedUser({
                      userId: _user._id,
                      firstName: _user.firstName,
                      lastName: _user.lastName,
                      emailId: _user.emailId,
                      mobileNumber: _user.mobileNumber,
                      role: _user.role,
                    });
                  }}
                >
                  <MdModeEditOutline />
                </button>
              </td>
            </tr>
          ))}
        </tbody>

        {/* <div className="p-4">
          <div className="overflow-x-auto">
            <table className="min-w-full border-collapse">
              <thead>
                <tr className="bg-gray-100">
                  <th className="p-2 text-left">Sr.</th>
                  <th className="p-2 text-left">Name</th>
                  <th className="p-2 text-left">Last Name</th>
                  <th className="p-2 text-left">Email</th>
                  <th className="p-2 text-left">Mobile Number</th>
                  <th className="p-2 text-left">Created Date</th>
                  <th className="p-2 text-left">Role</th>
                </tr>
              </thead>
              <tbody> Your table rows </tbody>
            </table>
          </div>
        </div> */}
      </table>

      {updateRoleOpen && (
        <ChangeUserRole
          userId={selectedUser.userId}
          firstName={selectedUser.firstName}
          lastName={selectedUser.lastName}
          emailId={selectedUser.emailId}
          mobileNumber={selectedUser.mobileNumber}
          role={selectedUser.role}
          onClose={() => {
            setUpdateRoleOpen(false);
            fetchAllUser();
          }}
        />
      )}
    </div>
  );
};

export default AllUser;
