import { useContext, useEffect, useState } from "react";
import UploadProduct from "../component/UploadProduct";
import SummaryAPI from "../common";
import AdminProductCard from "../component/AdminProductCard";
import Context from "../context";

const AllProduct = () => {
  const [openUploadProduct, setOpenUploadProduct] = useState(false);
  const [allProduct, setAllProduct] = useState([]);
  const context = useContext(Context);
  const setIsLoading = context?.setIsLoading;

  const fetchAllProduct = async () => {
    const response = await fetch(SummaryAPI.getAllProduct.URL);
    const dataResponse = await response.json();
    // console.log(dataResponse?.data, "here uploaded data");
    setAllProduct(dataResponse?.data || []);
    if (setIsLoading) setIsLoading(false);
  };

  useEffect(() => {
    if (setIsLoading) setIsLoading(true);
    fetchAllProduct();
  }, []);

  return (
    <div>
      <div className="py-2 px-4 flex justify-between items-center">
        <h2 className="font-bold text-lg">All Product</h2>
        <button
          className="border-2 border-customGold hover:border-customGoldHover hover:text-customGoldHover transition-all py-1 px-3 rounded-full "
          onClick={() => setOpenUploadProduct(true)}
        >
          Upload Product
        </button>
      </div>

      {/**all product */}
      <div className="flex items-center justify-center flex-wrap gap-5 pr-4 py-4 h-[calc(100vh-170px)] overflow-y-auto scrollbar-none">
        {allProduct.map((product, index) => {
          return (
            <AdminProductCard
              data={product}
              key={index + "_allProduct"}
              fetchdata={fetchAllProduct}
            />
          );
        })}
      </div>

      {/**upload product component */}
      {openUploadProduct && (
        <UploadProduct
          onClose={() => setOpenUploadProduct(false)}
          fetchData={fetchAllProduct}
        />
      )}
    </div>
  );
};

export default AllProduct;
