import React, { useState, ChangeEvent, useContext } from "react";
import { Link } from "react-router-dom";
import SummaryAPI from "../common";
import { customToast } from "../common/helperFunction";
import Context from "../context";

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const context = useContext(Context);
  const setIsLoading = context?.setIsLoading;

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleResetPassword = async () => {
    // Handle password reset logic here
    // console.log("Reset password for", email);
    if (setIsLoading) setIsLoading(true);
    try {
      const generateLink = await fetch(
        SummaryAPI.generateResetPasswordLink.URL,
        {
          method: SummaryAPI.generateResetPasswordLink.method,
          headers: { "content-type": "application/json" },
          credentials: "include",
          body: JSON.stringify({ email: email }),
        }
      );

      // console.log("generateLink", generateLink);
      const generatedLinkData = await generateLink.json();

      // console.log(generatedLinkData, "here checking");
      if (generatedLinkData.success) {
        customToast("Link sent to your email!", "success");
      } else {
        customToast("Failed to send Reset Link.", "error");
      }
    } catch {
      customToast("An error occurred while sending Reset Link.", "error");
    }
    if (setIsLoading) setIsLoading(false);
  };

  return (
    <div className="flex justify-center items-center min-h-screen ">
      <div className="p-8 rounded-lg shadow-lg w-full max-w-md ">
        <h2 className="text-2xl font-bold text-center mb-6">Forgot Password</h2>
        <p className="text-center mb-6 ">
          Enter your email address below, and we'll send you a link to reset
          your password.
        </p>
        <form className="space-y-4" onSubmit={(e) => e.preventDefault()}>
          <div>
            <label htmlFor="email" className="block text-sm font-medium ">
              Email
            </label>
            <input
              type="email"
              id="email"
              className="bg-black mt-1 block w-full p-3 border placeholder-customGold border-customGold rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-customGold focus:border-customGold"
              value={email}
              onChange={handleEmailChange}
              placeholder="Enter your email"
            />
          </div>
          <div>
            <button
              type="button"
              onClick={handleResetPassword}
              className="w-full py-3 px-4 bg-black text-customGold rounded-md border-2 border-customGold hover:bg-customGoldHover hover:text-black hover:border-black"
            >
              Send Reset Link
            </button>
          </div>
        </form>
        <p className="mt-6 text-center text-sm ">
          Remembered your password?{" "}
          <Link to="/login" className="text-customGold hover:underline">
            Log In
          </Link>
        </p>
      </div>
    </div>
  );
};

export default ForgotPassword;
