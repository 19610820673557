import { useState, useEffect, useContext } from "react";
import SummaryAPI from "../common";
import { checkoutItem, IPlaceOrder } from "../interface/pageInterface";
import { customToast } from "../common/helperFunction";
import Context from "../context";

const AllOrders = () => {
  const [allOrders, setAllOrders] = useState<IPlaceOrder[]>([]);
  const context = useContext(Context);
  const setIsLoading = context?.setIsLoading;

  // Fetch Orders
  const fetchAllOrders = async () => {
    const fetchOrdersData = await fetch(SummaryAPI.allOrderDetails.URL, {
      method: SummaryAPI.allOrderDetails.method,
      credentials: "include",
    });
    const orderResponse = await fetchOrdersData.json();
    if (orderResponse.success) {
      setAllOrders(orderResponse.data);
    }
    if (setIsLoading) setIsLoading(false);
  };

  // Update Payment Status
  const updatePaymentStatus = async (orderId: string, newStatus: string) => {
    if (setIsLoading) setIsLoading(true);
    const fetchPaymentData = await fetch(SummaryAPI.updatePaymentStatus.URL, {
      method: SummaryAPI.updatePaymentStatus.method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ orderId: orderId, paymentStatus: newStatus }),
      credentials: "include",
    });

    const paymentUpdated = await fetchPaymentData.json();
    if (paymentUpdated.success) {
      customToast(paymentUpdated.message, "success");
    } else if (paymentUpdated.error) {
      customToast(paymentUpdated.message, "error");
    }
    fetchAllOrders();
  };

  // Update Delivery Status
  const updateDeliveryStatus = async (orderId: string, newStatus: string) => {
    if (setIsLoading) setIsLoading(true);
    const fetchDeliveryData = await fetch(SummaryAPI.updateDeliveryStatus.URL, {
      method: SummaryAPI.updateDeliveryStatus.method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ orderId: orderId, deliveryStatus: newStatus }),
      credentials: "include",
    });

    const deliveryUpdated = await fetchDeliveryData.json();
    if (deliveryUpdated.success) {
      customToast(deliveryUpdated.message, "success");
    } else if (deliveryUpdated.error) {
      customToast(deliveryUpdated.message, "error");
    }
    fetchAllOrders();
  };

  // Send Status Mail
  const sendStatusMail = async (
    orderId: string,
    paymentStatus: string,
    deliveryStatus: string,
    customerEmail: string,
    customerName: string,
    items: checkoutItem[],
    paymentMode: string
  ) => {
    if (setIsLoading) setIsLoading(true);

    // Generate HTML for the items
    const itemsHtml = items
      .map(
        (item) => `
        <tr>
          <td style="padding: 10px; border: 1px solid #ddd;">${item.name}</td>
          <td style="padding: 10px; border: 1px solid #ddd; text-align: center;">${
            item.quantity
          }</td>
          <td style="padding: 10px; border: 1px solid #ddd; text-align: center;">${
            item.size
          }</td>
          <td style="padding: 10px; border: 1px solid #ddd; text-align: right;">$${item.price.toFixed(
            2
          )}</td>
        </tr>
      `
      )
      .join("");

    const bodyHtml = `
      <h2>Order Status Update</h2>
      <p>Dear ${customerName},</p>
      <p>We are reaching out to inform you about the current status of your order with ID: <strong>${orderId}</strong>.</p>
  
      <table style="width: 100%; border-collapse: collapse; margin-top: 15px;">
        <thead>
          <tr>
            <th style="padding: 10px; border: 1px solid #ddd; background-color: #f7f7f7; font-weight: bold;">Order ID</th>
            <td style="padding: 10px; border: 1px solid #ddd;">${orderId}</td>
          </tr>
          <tr>
            <th style="padding: 10px; border: 1px solid #ddd; background-color: #f7f7f7; font-weight: bold;">Payment Status</th>
            <td style="padding: 10px; border: 1px solid #ddd;">${paymentStatus}</td>
          </tr>
          <tr>
            <th style="padding: 10px; border: 1px solid #ddd; background-color: #f7f7f7; font-weight: bold;">Delivery Status</th>
            <td style="padding: 10px; border: 1px solid #ddd;">${deliveryStatus}</td>
          </tr>
          <tr>
            <th style="padding: 10px; border: 1px solid #ddd; background-color: #f7f7f7; font-weight: bold;">Payment Mode</th>
            <td style="padding: 10px; border: 1px solid #ddd;">${paymentMode}</td>
          </tr>
        </thead>
      </table>
  
      <h3 style="margin-top: 20px;">Order Items</h3>
      <table style="width: 100%; border-collapse: collapse; margin-top: 10px;">
        <thead>
          <tr>
            <th style="padding: 10px; border: 1px solid #ddd; background-color: #f7f7f7; font-weight: bold;">Item Name</th>
            <th style="padding: 10px; border: 1px solid #ddd; background-color: #f7f7f7; font-weight: bold; text-align: center;">Quantity</th>
            <th style="padding: 10px; border: 1px solid #ddd; background-color: #f7f7f7; font-weight: bold; text-align: center;">Size</th>
            <th style="padding: 10px; border: 1px solid #ddd; background-color: #f7f7f7; font-weight: bold; text-align: right;">Price</th>
          </tr>
        </thead>
        <tbody>
          ${itemsHtml}
        </tbody>
      </table>
  
      <p style="margin-top: 15px;">We appreciate your patience and are committed to keeping you informed on any further updates regarding your order.</p>
      <p>Thank you for shopping with us!</p>
      <p>Best regards,<br>SABR Team</p>
    `;

    const response = await fetch(SummaryAPI.sendmail.URL, {
      method: SummaryAPI.sendmail.method,
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        to: customerEmail,
        subject: `Order Status - Your Order with SABR`,
        bodyText: `Order Status Update for Order ID: ${orderId}`,
        bodyHtml: bodyHtml,
      }),
    });

    const mailResponse = await response.json();
    if (mailResponse.success) {
      customToast("Email sent successfully!", "success");
    } else {
      customToast("Failed to send email.", "error");
    }
    if (setIsLoading) setIsLoading(false);
  };

  useEffect(() => {
    if (setIsLoading) setIsLoading(true);
    fetchAllOrders();
  }, []);

  const thClassName =
    "px-3 py-3 border-b-2 border-gray-500 bg-gray-900 text-left text-xs sm:text-sm font-semibold whitespace-nowrap text-center";
  const tdClassName =
    "px-3 py-2 border-b border-gray-500 text-xs sm:text-sm truncate text-center";

  return (
    <div className="overflow-x-auto scrollbar-none w-full max-w-screen-xl mx-auto">
      <h2 className="text-xl font-semibold mb-4">All Orders</h2>
      <table className="w-full orderTable">
        <thead>
          <tr>
            <th className={thClassName}>Order ID</th>
            <th className={thClassName}>User ID</th>
            <th className={thClassName}>Email ID</th>
            <th className={thClassName}>Customer Name</th>
            <th className={thClassName}>Customer Mobile Number</th>
            <th className={thClassName}>Customer Address</th>
            <th className={thClassName}>Payment Mode</th>
            <th className={thClassName}>Payment Status</th>
            <th className={thClassName}>Delivery Status</th>
            <th className={thClassName}>Order Items</th>
            <th className={thClassName}>Order Date</th>
            <th className={thClassName}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {allOrders.map((_order, _index) => (
            <tr key={_index}>
              <td className={tdClassName}>{_order._id}</td>
              <td className={tdClassName}>{_order.userId}</td>
              <td className={tdClassName}>{_order.customerEmailId}</td>
              <td className={tdClassName}>{_order.customerName}</td>
              <td className={tdClassName}>{_order.customerMobile}</td>
              <td className={tdClassName}>{_order.customerAddress}</td>
              <td className={tdClassName}>{_order.paymentMode}</td>
              <td className={tdClassName}>
                <select
                  value={_order.paymentStatus}
                  onChange={(e) =>
                    updatePaymentStatus(_order._id, e.target.value)
                  }
                  className="bg-black mt-1 block w-full p-3 border placeholder-customGold border-customGold rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-customGold focus:border-customGold"
                >
                  <option value="Pending">Pending</option>
                  <option value="Completed">Completed</option>
                  <option value="Failed">Failed</option>
                </select>
              </td>
              <td className={tdClassName}>
                <select
                  value={_order.deliveryStatus}
                  onChange={(e) =>
                    updateDeliveryStatus(_order._id, e.target.value)
                  }
                  className="bg-black mt-1 block w-full p-3 border placeholder-customGold border-customGold rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-customGold focus:border-customGold"
                >
                  <option value="Processing">Processing</option>
                  <option value="Shipped">Shipped</option>
                  <option value="Delivered">Delivered</option>
                </select>
              </td>
              <td className={tdClassName}>
                {_order.items.map((item, _itemKey) => (
                  <div key={item.id + _itemKey} className="my-1">
                    {item.name} - {item.quantity} x {item.size}
                  </div>
                ))}
              </td>
              <td className={tdClassName}>
                {new Date(_order.createdAt).toLocaleDateString("en-IN", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })}{" "}
                {new Date(_order.createdAt).toLocaleTimeString("en-IN", {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                })}
              </td>
              <td className={tdClassName}>
                <button
                  onClick={() =>
                    sendStatusMail(
                      _order._id,
                      _order.paymentStatus,
                      _order.deliveryStatus,
                      _order.customerEmailId,
                      _order.customerName,
                      _order.items,
                      _order.paymentMode
                    )
                  }
                  className="bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-600"
                >
                  Send Mail
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AllOrders;
