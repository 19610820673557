import { ChangeEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { product } from "../interface/componentInterface";
import HorizontalCardProduct from "../component/HorizontalCardProduct";

const CategoryProduct = () => {
  const param = useParams();
  const [sortOption, setSortOption] = useState<
    "lowToHigh" | "highToLow" | "none"
  >("none");
  const handleSortChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSortOption(event.target.value as "lowToHigh" | "highToLow" | "none");
  };

  return (
    <div className="container mx-auto p-6 sm:p-8 lg:p-12">
      <h1 className="text-3xl font-bold mb-6 text-center">Products</h1>
      {/* <p>{param.categoryName}</p> */}

      {/* Sort by Price */}
      <div className="mb-6 flex justify-center flex-wrap gap-4">
        <div className="ml-4">
          <h2 className="text-lg font-medium mb-2">Sort by Price:</h2>
          <select
            value={sortOption}
            onChange={handleSortChange}
            className="p-3 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-customGold focus:border-customGold"
          >
            <option value="none">None</option>
            <option value="lowToHigh">Low to High</option>
            <option value="highToLow">High to Low</option>
          </select>
        </div>
      </div>

      <HorizontalCardProduct
        categoryName={param.categoryName ? param.categoryName : "all"}
        headerName={param.categoryName ? param.categoryName : "all"}
        useSlider={false}
        sortOption={sortOption}
        showAddToCart={false}
      />
    </div>
  );
};

export default CategoryProduct;
