import React, { useState, useEffect, useContext } from "react";
import { product } from "../interface/componentInterface";
import { useNavigate, useParams } from "react-router-dom";
import SummaryAPI from "../common";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import displayCurrency from "../helper/displayCurrency";
import HorizontalCardProduct from "../component/HorizontalCardProduct";
import useAddToCart from "../helper/addToCart";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { customToast, productCategory } from "../common/helperFunction";
import Context from "../context";
import { FaMinus } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";

const ProductDetails = () => {
  const [productDetails, setProductDetails] = useState<
    product & { size: string[] }
  >({
    _id: undefined,
    productName: "",
    category: "",
    productImage: [{ url: "", publicId: "" }],
    description: "",
    price: 0,
    sellingPrice: 0,
    size: [],
  });
  const addToCart = useAddToCart();
  const [selectedSize, setSelectedSize] = useState<string>("");
  const [activeImage, setActiveImage] = useState("");
  const [quantity, setQuantity] = useState(1);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const userDetails = useSelector((state: RootState) => state?.user?.user);
  const [isProcessing, setIsProcessing] = useState(false);

  const context = useContext(Context);
  const setIsLoading = context?.setIsLoading;

  const locale = context?.locale || "en-IN";
  const currencyName = context?.currencyCode || "INR";
  const conversionRate = context?.conversionRate || 1;

  const params = useParams();

  const fetchProductDetail = async () => {
    setLoading(true);
    if (setIsLoading) setIsLoading(true);
    const response = await fetch(SummaryAPI.productDetails.URL, {
      method: SummaryAPI.productDetails.method,
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ productId: params.id }),
    });
    const dataResponse = await response.json();
    if (dataResponse.success) {
      setProductDetails(dataResponse.data);
      setActiveImage(dataResponse.data.productImage[0]?.url || "");
    }
    setLoading(false);
    if (setIsLoading) setIsLoading(false);
  };

  useEffect(() => {
    fetchProductDetail();
  }, [params.id]);

  const handleMouseEnterProduct = (imageURL: string) => {
    setActiveImage(imageURL);
  };

  const handleAddToCart = async (
    e: React.MouseEvent<HTMLButtonElement>,
    id: string
  ) => {
    if (setIsLoading) setIsLoading(true);
    if (selectedSize) {
      await addToCart(e, id, quantity, selectedSize);
    } else {
      customToast("Please select a size before adding to cart", "error");
    }
    if (setIsLoading) setIsLoading(false);
  };

  const handleBuyProduct = async (
    e: React.MouseEvent<HTMLButtonElement>,
    id: string
  ) => {
    if (!isProcessing) {
      setIsProcessing(true);
      if (setIsLoading) setIsLoading(true);
      if (userDetails) {
        const itemDetails = {
          id: id,
          name: productDetails.productName,
          imageUrl: productDetails.productImage[0].url,
          price: productDetails.sellingPrice,
          quantity: quantity,
          size: selectedSize,
        };
        if (selectedSize) {
          navigate(`/checkout/${itemDetails.id}`, {
            state: { checkoutItems: [itemDetails] },
          });
        } else {
          customToast("Please select a size before proceeding", "error");
        }
      } else {
        customToast("Please login before proceeding for checkout", "error");
        navigate(`/login`);
      }
      setIsProcessing(false);
      if (setIsLoading) setIsLoading(false);
    }
  };

  const increaseQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  const discountPercentage = Math.round(
    ((productDetails.price - productDetails.sellingPrice) /
      productDetails.price) *
      100
  );

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex flex-col lg:flex-row gap-8 lg:gap-12">
        {/* Left Section: Product Images */}
        <div className="flex flex-col gap-6 lg:w-1/2">
          <div className="relative w-full h-0 pb-[177.78%] overflow-hidden rounded-lg bg-gray-100 max-h-[60vh] aspect-[9/13]">
            <Zoom>
              <img
                src={activeImage}
                alt="Product"
                className="absolute inset-0 w-full h-full object-cover"
              />
            </Zoom>
          </div>
          <div className="flex overflow-x-auto gap-4">
            {loading
              ? Array(4)
                  .fill(null)
                  .map((_, index) => (
                    <div
                      key={index}
                      className="w-20 h-28 bg-gray-200 animate-pulse rounded-lg aspect-[9/13]"
                    />
                  ))
              : productDetails?.productImage?.map((imgURL) => (
                  <div
                    key={imgURL.publicId}
                    className="w-20 h-28 cursor-pointer rounded-lg overflow-hidden aspect-[9/13]"
                    onMouseEnter={() => handleMouseEnterProduct(imgURL.url)}
                    onClick={() => handleMouseEnterProduct(imgURL.url)}
                  >
                    <img
                      src={imgURL.url}
                      className="w-full h-full object-cover"
                      alt="Product Thumbnail"
                    />
                  </div>
                ))}
          </div>
        </div>

        {/* Right Section: Product Details */}
        <div className="flex flex-col gap-6 lg:w-1/2">
          {loading ? (
            <div className="space-y-4">
              <div className="h-8 bg-gray-200 animate-pulse rounded w-full"></div>
              <div className="h-6 bg-gray-200 animate-pulse rounded w-full"></div>
              <div className="h-6 bg-gray-200 animate-pulse rounded w-1/2"></div>
              <div className="h-6 bg-gray-200 animate-pulse rounded w-1/4"></div>
            </div>
          ) : (
            <>
              <h2 className="text-2xl font-bold">
                {productDetails?.productName}
              </h2>
              <p className="text-gray-700">
                {
                  productCategory.find(
                    (_pc) => _pc.value === productDetails?.category
                  )?.label
                }
              </p>
              <div className="flex flex-col sm:flex-row items-start sm:items-center gap-2 text-xl font-semibold">
                <p>
                  {displayCurrency(
                    productDetails.sellingPrice * conversionRate,
                    locale,
                    currencyName
                  )}
                </p>
                <p className="text-gray-500 line-through">
                  {displayCurrency(
                    productDetails.price * conversionRate,
                    locale,
                    currencyName
                  )}
                </p>
                {discountPercentage > 0 && (
                  <span className="text-lg text-green-600 font-medium">
                    {discountPercentage}% OFF
                  </span>
                )}
              </div>
              {productDetails.size.length > 0 && (
                <div>
                  <label className="block text-lg font-medium">Size:</label>
                  <select
                    value={selectedSize}
                    onChange={(e) => setSelectedSize(e.target.value)}
                    className="bg-white border mt-2 rounded-md w-full p-3"
                  >
                    <option value="">Select Size</option>
                    {productDetails.size.map((size, index) => (
                      <option key={index} value={size}>
                        {size}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              <div className="flex items-center gap-4 mt-4">
                <button
                  onClick={decreaseQuantity}
                  disabled={quantity <= 1}
                  className={`px-4 py-2 border rounded ${
                    quantity <= 1 ? "text-gray-200" : "text-customGold"
                  }`}
                >
                  <FaMinus />
                </button>
                <span className="text-lg">{quantity}</span>
                <button
                  onClick={increaseQuantity}
                  className="px-4 py-2 border rounded"
                >
                  <FaPlus />
                </button>
              </div>
              <div className="flex flex-col sm:flex-row gap-4 mt-6">
                <button
                  onClick={(e) =>
                    handleBuyProduct(e, productDetails?._id as string)
                  }
                  disabled={isProcessing || loading}
                  className={`px-6 py-2 border rounded ${
                    isProcessing || loading
                      ? "bg-gray-300 text-gray-700 cursor-not-allowed"
                      : "bg-customGold text-black hover:bg-black hover:text-customGold"
                  }`}
                >
                  Buy Now
                </button>
                <button
                  onClick={(e) =>
                    handleAddToCart(e, productDetails?._id as string)
                  }
                  disabled={isProcessing || loading}
                  className={`px-6 py-2 border rounded ${
                    isProcessing || loading
                      ? "bg-gray-300 text-gray-700 cursor-not-allowed"
                      : "bg-black text-customGold hover:bg-customGold hover:text-black"
                  }`}
                >
                  Add to Cart
                </button>
              </div>
              <div className="mt-4">
                <p className="font-medium">Description:</p>
                <div
                  className="whitespace-pre-line text-gray-700"
                  dangerouslySetInnerHTML={{
                    __html: productDetails.description,
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <HorizontalCardProduct
        headerName={""}
        categoryName={"all"}
        useSlider={true}
        showAddToCart={false}
      />
    </div>
  );
};

export default ProductDetails;
