import React from "react";

const AboutUs: React.FC = () => {
  const websiteName = "SABR";
  const emailId = "sabrcollection29@gmail.com";
  const contactNumber = "9920785687";

  return (
    <div className="min-h-screen py-10">
      <div className="container mx-auto px-4">
        <div className="shadow-md rounded-lg p-8">
          <h1 className="text-3xl font-bold text-center mb-8">About Us</h1>

          <div className="space-y-6">
            <p className="text-lg ">
              Welcome to {websiteName}! We are passionate about providing the
              best products and services to our customers. Our journey started
              with a simple idea: to make shopping easy, accessible, and
              enjoyable for everyone.
            </p>

            <p className="text-lg ">
              At {websiteName}, we believe in quality, affordability, and
              customer satisfaction. We work tirelessly to curate a wide range
              of products that meet the needs of our diverse customer base.
              Whether you’re looking for the latest trends or timeless classics,
              we have something for everyone.
            </p>

            <h2 className="text-2xl font-semibold mt-8">Our Mission</h2>
            <p className="text-lg ">
              Our mission is to revolutionize the online shopping experience by
              offering a seamless, user-friendly platform that caters to all
              your needs. We strive to provide exceptional customer service,
              fast delivery, and a secure shopping environment.
            </p>

            <h2 className="text-2xl font-semibold mt-8">Why Choose Us?</h2>
            <ul className="list-disc list-inside space-y-2 text-lg ">
              <li>High-quality products at competitive prices.</li>
              <li>Wide range of items across various categories.</li>
              <li>Secure and easy-to-use online shopping platform.</li>
              <li>Fast and reliable delivery service.</li>
              <li>Dedicated customer support team.</li>
            </ul>

            <h2 className="text-2xl font-semibold mt-8">Our Team</h2>
            <p className="text-lg ">
              Our team is made up of dedicated professionals who are passionate
              about what they do. From product sourcing to customer service,
              every member of our team is committed to delivering the best
              possible experience for our customers.
            </p>

            <h2 className="text-2xl font-semibold mt-8">Contact Us</h2>
            <p className="text-lg ">
              If you have any questions, feedback, or concerns, feel free to
              reach out to us. We are here to help!
            </p>
            <p className="text-lg ">
              Email:{" "}
              <a
                href={`mailto:${emailId}`}
                className="text-blue-500 hover:underline"
              >
                {emailId}
              </a>
            </p>
            <p className="text-lg ">
              Phone:{" "}
              <a
                href={`tel:${contactNumber}`}
                className="text-blue-500 hover:underline"
              >
                {contactNumber}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
