import React, { useContext, useEffect, useState } from "react";
import ROLE from "../common/role";
import { MdCloseFullscreen } from "react-icons/md";
import { changeUserRoleProps } from "../interface/componentInterface";
import SummaryAPI from "../common";
import { customToast } from "../common/helperFunction";
import Context from "../context";

const ChangeUserRole: React.FC<changeUserRoleProps> = (props) => {
  const { userId, firstName, lastName, emailId, mobileNumber, role, onClose } =
    props;
  const [userRole, setUserRole] = useState("");
  const context = useContext(Context);
  const setIsLoading = context?.setIsLoading;

  useEffect(() => {
    setUserRole(role);
  }, [role]);

  const handleOnChange = (e: any) => {
    setUserRole(e.target.value);
  };

  const updateUserRole = async () => {
    if (setIsLoading) setIsLoading(true);
    const fetchData = await fetch(SummaryAPI.updateUser.URL, {
      method: SummaryAPI.updateUser.method,
      credentials: "include",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        userId: userId,
        role: userRole,
      }),
    });

    const dataResponse = await fetchData.json();

    if (dataResponse.success) {
      customToast(dataResponse.message, "success");
      onClose();
    }

    if (dataResponse.error) {
      customToast(dataResponse.message, "error");
    }
    if (setIsLoading) setIsLoading(false);
  };
  return (
    <div className="fixed top-0 left-0 w-full h-full z-10 flex justify-center items-center bg-black bg-opacity-50">
      <div className="bg-white border border-customGold shadow-customShadow-sm p-4 w-full max-w-sm">
        <button className="block ml-auto" onClick={onClose}>
          <MdCloseFullscreen />
        </button>
        <h1 className="pb-4 text-lg font-medium">Change User Role</h1>
        <p>Name: {firstName + " " + lastName}</p>
        <p>Email Id: {emailId}</p>
        <p>Mobile Number: {mobileNumber}</p>
        <div className="flex items-center justify-between my-4">
          <p>Role: </p>
          <select
            className=" bg-black border placeholder-customGold border-customGold rounded-md shadow-sm focus:outline-none px-4 py-1"
            value={userRole}
            onChange={handleOnChange}
          >
            {Object.values(ROLE).map((_role) => {
              return (
                <option value={_role} key={_role}>
                  {_role}
                </option>
              );
            })}
          </select>
        </div>
        <button
          className="w-fit mx-auto block py-1 px-3 rounded bg-black"
          onClick={updateUserRole}
        >
          Change Role
        </button>
      </div>
    </div>
  );
};

export default ChangeUserRole;
