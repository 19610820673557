import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "../store/store";
import { customToast } from "../common/helperFunction";
import { checkoutPageState } from "../interface/componentInterface";
import SummaryAPI from "../common";
import Context from "../context";
import displayCurrency from "../helper/displayCurrency";

const CheckoutPage: React.FC = () => {
  const location = useLocation();
  const { checkoutItems }: checkoutPageState = location.state || {
    checkoutItems: [],
  }; // Access passed items

  const [paymentMethod, setPaymentMethod] = useState<string>("COD");
  const [upiId, setUpiId] = useState<string>("");
  const [isProcessing, setIsProcessing] = useState(false);

  const [name, setName] = useState<string>("");
  const [mobile, setMobile] = useState<string>("");
  const [address, setAddress] = useState<string>("");

  const navigate = useNavigate();
  const userDetails = useSelector(
    (state: RootState) => state?.user?.user || null
  );

  const context = useContext(Context);
  // const isLoading = context?.isLoading;
  const setIsLoading = context?.setIsLoading;

  const locale = context?.locale || "en-IN";
  const currencyName = context?.currencyCode || "INR";
  const conversionRate = context?.conversionRate || 1;

  const calculateTotal = () => {
    return checkoutItems.reduce(
      (total, item) => total + item.price * item.quantity,
      0
    );
  };

  useEffect(() => {
    if (!userDetails) {
      customToast("Please login before proceeding for checkout", "error");
      navigate(`/login`);
    }
  }, [userDetails]);

  const deleteCartDataFromDB = async (
    productId: string,
    productSize: string
  ) => {
    if (setIsLoading) setIsLoading(true);
    const response = await fetch(SummaryAPI.removeFromCartController.URL, {
      method: SummaryAPI.removeFromCartController.method,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        productId,
        size: productSize,
      }),
    });

    const responseData = await response.json();

    if (responseData.success) {
      context && context.fetchUserAddToCart();
    }
    if (setIsLoading) setIsLoading(false);
  };

  const handlePlaceOrder = async () => {
    if (!name || !mobile || !address) {
      customToast("Please fill in all the required fields.", "error");
      return;
    }

    if (paymentMethod === "UPI" && upiId === "") {
      alert("Please enter a valid UPI ID.");
      return;
    }

    if (!isProcessing) {
      setIsProcessing(true);

      if (setIsLoading) setIsLoading(true);
      try {
        const dataResponse = await fetch(SummaryAPI.placeOrder.URL, {
          method: SummaryAPI.placeOrder.method,
          credentials: "include",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            userId: userDetails?._id,
            items: checkoutItems,
            paymentMode: paymentMethod,
            customerDetails: {
              name,
              mobile,
              address,
              emailId: userDetails?.emailId,
            }, // Include additional details
          }),
        });

        const data = await dataResponse.json();

        if (data.success) {
          await Promise.all(
            checkoutItems.map((item) =>
              deleteCartDataFromDB(item.id, item.size)
            )
          );
          customToast(data.message, "success");
          navigate("/");

          // Generate HTML for order summary
          const orderSummaryHtml = checkoutItems
            .map(
              (item) =>
                `<tr>
                 <td style="padding: 8px; border: 1px solid #ddd;">${
                   item.name
                 }</td>
                 <td style="padding: 8px; border: 1px solid #ddd;">${
                   item.quantity
                 }</td>
                 <td style="padding: 8px; border: 1px solid #ddd;">
                 ${displayCurrency(
                   Number(item.price * item.quantity) * conversionRate,
                   locale,
                   currencyName
                 )}
                 </td>
               </tr>`
            )
            .join("");

          const bodyHtml = `
            <h2>Order Confirmation</h2>
            <p>Dear ${name},</p>
            <p>Thank you for your order! Here are your order details:</p>
            <table style="width: 100%; border-collapse: collapse;">
              <thead>
                <tr>
                  <th style="padding: 8px; border: 1px solid #ddd;">Item Name</th>
                  <th style="padding: 8px; border: 1px solid #ddd;">Quantity</th>
                  <th style="padding: 8px; border: 1px solid #ddd;">Total Price</th>
                </tr>
              </thead>
              <tbody>
                ${orderSummaryHtml}
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="2" style="padding: 8px; border: 1px solid #ddd; font-weight: bold;">Grand Total</td>
                  <td style="padding: 8px; border: 1px solid #ddd;">${displayCurrency(
                    calculateTotal() * conversionRate,
                    locale,
                    currencyName
                  )}</td>
                </tr>
              </tfoot>
            </table>
            <p>Delivery Address: ${address}</p>
            <p>Contact Number: ${mobile}</p>
            <p>Payment Method: ${
              paymentMethod === "COD" ? "Cash on Delivery" : "UPI"
            }</p>
          `;

          // <hr style="border: none; border-top: 1px solid #ddd; margin: 30px 0;" />
          //   <p style="font-size: 12px; color: #888;">
          //     Please do not reply to this email. For any assistance, contact us at
          //     <a href="mailto:support@sabrcollection.com" style="color: #007bff; text-decoration: none;">support@sabrcollection.com</a>.
          //   </p>
          // Send email with order summary
          const mail = await fetch(SummaryAPI.sendmail.URL, {
            method: SummaryAPI.sendmail.method,
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify({
              to: userDetails?.emailId,
              subject: `Order Confirmation - Your Order with SABR`,
              bodyText: `Order Confirmation for ${name}`,
              bodyHtml: bodyHtml,
            }),
          });

          const mailResponse = await mail.json();
          if (!mailResponse.success) {
            customToast("Failed to send order confirmation email.", "error");
          }
        } else if (data.error) {
          customToast(data.message, "error");
        }
      } catch (error) {
        customToast("An error occurred. Please try again later.", "error");
      }
      setIsProcessing(false);
      if (setIsLoading) setIsLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-semibold text-center mb-6">Checkout</h1>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Order Summary */}
        <div className="border border-customGold p-4 rounded-lg shadow-md">
          <h2 className="text-xl font-bold mb-4">Order Summary</h2>
          <div className="space-y-4">
            {checkoutItems.map((item) => (
              <div key={item.id} className="flex justify-between items-center">
                <p className="text-lg">{item.name}</p>
                <p className="text-lg">x{item.quantity}</p>
                <p className="text-lg">
                  {displayCurrency(
                    Number(item.price * item.quantity) * conversionRate,
                    locale,
                    currencyName
                  )}
                </p>
              </div>
            ))}
          </div>
          <div className="mt-4 flex justify-between font-semibold text-xl">
            <p>Total: </p>
            <p>
              {displayCurrency(
                Number(calculateTotal()) * conversionRate,
                locale,
                currencyName
              )}
            </p>
          </div>
        </div>

        {/* Payment Details */}
        <div className="border border-customGold p-4 rounded-lg shadow-md">
          <h2 className="text-xl font-bold mb-4">Payment Information</h2>
          <form className="space-y-4">
            {/* Name */}
            <div>
              <label className="block text-sm font-medium mb-2">Name</label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className=" bg-black w-full border placeholder-customGold border-customGold rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-customGold focus:border-customGold"
                placeholder="Enter your name"
              />
            </div>

            {/* Mobile Number */}
            <div>
              <label className="block text-sm font-medium mb-2">
                Mobile Number
              </label>
              <input
                type="text"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                className="bg-black w-full border placeholder-customGold border-customGold rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-customGold focus:border-customGold"
                placeholder="Enter your mobile number"
              />
            </div>

            {/* Address */}
            <div>
              <label className="block text-sm font-medium mb-2">Address</label>
              <textarea
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                className="bg-black w-full border placeholder-customGold border-customGold focus:outline-none focus:ring-2 focus:ring-customGold focus:border-customGold rounded-md p-2"
                placeholder="Enter your address"
              ></textarea>
            </div>

            {/* Payment Method */}
            <div>
              <label className="block text-sm font-medium">
                Payment Method
              </label>
              <div className="flex items-center gap-4">
                <label className="flex items-center cursor-pointer">
                  <input
                    type="radio"
                    name="paymentMethod"
                    value="COD"
                    checked={paymentMethod === "COD"}
                    onChange={() => setPaymentMethod("COD")}
                    className="peer sr-only"
                  />
                  <span className="w-4 h-4 inline-block mr-2 rounded-full border-2 border-customGold peer-checked:border-customGold peer-checked:bg-customGold peer-checked:ring-2 peer-checked:ring-customGold"></span>
                  Cash on Delivery
                </label>
                <label className="flex items-center cursor-pointer">
                  <input
                    type="radio"
                    name="paymentMethod"
                    value="UPI"
                    checked={paymentMethod === "UPI"}
                    onChange={() => setPaymentMethod("UPI")}
                    className="peer sr-only"
                    disabled
                  />
                  <span className="w-4 h-4 inline-block mr-2 rounded-full border-2 border-gray-400 peer-checked:border-customGold peer-checked:bg-customGold peer-checked:ring-2 peer-checked:ring-customGold"></span>
                  UPI
                </label>
              </div>
            </div>

            {paymentMethod === "UPI" && (
              <div>
                <label
                  htmlFor="upiId"
                  className="block text-sm font-medium text-gray-700"
                >
                  UPI ID
                </label>
                <input
                  type="text"
                  id="upiId"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                  placeholder="your-upi-id@bank"
                  value={upiId}
                  onChange={(e) => setUpiId(e.target.value)}
                />
              </div>
            )}

            <button
              type="button"
              className="bg-customGold text-white px-6 py-2 mt-4 w-full rounded-md"
              onClick={handlePlaceOrder}
              disabled={isProcessing}
            >
              Place Order
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CheckoutPage;
