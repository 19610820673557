import React from "react";

const Footer: React.FC = () => {
  const websiteName = "SABR";
  const emailId = "sabrcollection29@gmail.com";
  const contactNumber = "9920785687";

  return (
    <div className="bg-white">
      {/* Divider above the footer */}
      <div className="container mx-auto px-4">
        <hr className="border-t-2 border-customGold" />
      </div>

      <footer className="text-customGold py-8 bg-white">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Logo and Description */}
            <div>
              <h2 className="text-2xl font-bold mb-4">{websiteName}</h2>
              <p className="text-sm">
                Our mission is to revolutionize the online shopping experience
                by offering a seamless, user-friendly platform that caters to
                all your needs. We strive to provide exceptional customer
                service, fast delivery, and a secure shopping environment.
              </p>
            </div>

            {/* Contact Information */}
            <div>
              <h3 className="text-lg font-semibold mb-4">Contact Us</h3>
              <p className="text-sm">Mahim</p>
              <p className="text-sm">Mumbai, Maharashtra, India - 400016</p>
              <p
                className="text-sm mt-2"
                style={{ overflowWrap: "break-word" }}
              >
                Email: {emailId}
              </p>
              <p className="text-sm">Phone: {contactNumber}</p>
            </div>
          </div>

          <div className="mt-8 border-t-2 border-customGold pt-4 text-center text-sm">
            <p>
              &copy; {new Date().getFullYear()} {websiteName}. All rights
              reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
