import { Outlet } from "react-router-dom";
import "./App.css";
import Header from "./component/Header";
import Footer from "./component/Footer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import SummaryAPI from "./common";
import Context from "./context";
import { useDispatch } from "react-redux";
import { setUserDetail } from "./store/userSlice";
import ScrollToTop from "./component/ScrollToTop";
import axios from "axios";
import WordTyping from "./pages/WordTyping";
import { getLocaleDetails } from "./common/helperFunction";

const App = () => {
  const dispatch = useDispatch();
  const [cartProductCount, setCardProductCount] = useState(0);
  const [currencyCode, setCurrencyCode] = useState("INR");
  const [locale, setLocale] = useState("en-IN");
  const [conversionRate, setConversionRate] = useState(1);
  const [typingComplete, setTypingComplete] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const fetchUserDetails = async () => {
    setIsLoading(true);
    try {
      const dataResponse = await fetch(SummaryAPI.currentUser.URL, {
        method: SummaryAPI.currentUser.method,
        credentials: "include",
      });
      const dataAPI = await dataResponse.json();
      if (dataAPI.success) {
        dispatch(setUserDetail(dataAPI.data));
      }
    } catch (error) {
      console.error("Failed to fetch user details", error);
    }
    setIsLoading(false);
  };

  const fetchUserAddToCart = async () => {
    setIsLoading(true);
    try {
      // setIsLoading(true);
      const dataResponse = await fetch(SummaryAPI.countAddToCartProduct.URL, {
        method: SummaryAPI.countAddToCartProduct.method,
        credentials: "include",
      });
      const dataAPI = await dataResponse.json();
      if (dataAPI.success) {
        setCardProductCount(dataAPI.data.count);
      }
      // setIsLoading(false);
    } catch (error) {
      console.error("Failed to fetch cart product count", error);
    }
    setIsLoading(false);
  };

  // const fetchUserCountry = async () => {
  //   setIsLoading(true);
  //   try {
  //     const dataResponse = await fetch(SummaryAPI.fetchCountry.URL, {
  //       method: SummaryAPI.fetchCountry.method,
  //     });
  //     const response = await dataResponse.json();

  //     console.log(response, "checking");
  //     const currencyCode = response.data.currency;
  //     const localeTemp = navigator.language;
  //     setCurrencyCode(currencyCode);
  //     setLocale(localeTemp);
  //   } catch (error) {
  //     console.error("Error fetching location data:", error);
  //   }
  //   setIsLoading(false);
  // };

  const fetchConversionRate = async () => {
    setIsLoading(true);
    try {
      const dataResponse = await fetch(SummaryAPI.fetchCurrencyRate.URL, {
        method: SummaryAPI.fetchCurrencyRate.method,
      });
      const response = await dataResponse.json();

      const currencyRate = response.data.inr[currencyCode.toLowerCase()];
      setConversionRate(currencyRate);
    } catch (error) {
      console.error("Error fetching conversionRate data:", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    // fetchUserCountry();
    fetchUserDetails();
    fetchUserAddToCart();
  }, []);

  useEffect(() => {
    const currencyC = getLocaleDetails(locale)?.currency;
    setCurrencyCode(currencyC || "INR");
  }, [locale]);

  useEffect(() => {
    fetchConversionRate();
  }, [currencyCode]);

  const onChange = (isVisible: boolean) => {
    setTypingComplete(isVisible);
  };

  return (
    <>
      {!typingComplete && isLoading && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          style={{ top: 0, left: 0, right: 0, bottom: 0 }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/loader.gif`}
            alt="Loading..."
            className="w-52 h-52"
          />
        </div>
      )}
      {typingComplete && (
        <div
          className={`fixed inset-0 flex items-center justify-center bg-white transition-opacity duration-1000 ease-in-out ${
            typingComplete ? "opacity-100" : "opacity-0"
          }`}
          style={{ background: "black" }}
        >
          <WordTyping onChange={onChange} />
        </div>
      )}
      <Context.Provider
        value={{
          currencyCode,
          locale,
          fetchUserDetails,
          cartProductCount,
          fetchUserAddToCart,
          conversionRate,
          isLoading,
          setIsLoading,
          setLocale,
        }}
      >
        <div
          className={`transition-opacity duration-1000 ease-in-out ${
            typingComplete ? "opacity-0 cursor-none" : "opacity-100"
          }`}
        >
          <ToastContainer
            theme="dark"
            autoClose={2000}
            closeOnClick={true}
            pauseOnHover={false}
          />
          <Header />
          <main className="min-h-[calc(100vh-120px)] pt-20 exsm:grid-cols-2 bg-white">
            <ScrollToTop />
            <Outlet />
          </main>
          <Footer />
        </div>
      </Context.Provider>
    </>
  );
};

export default App;
