import React, { useState, ChangeEvent } from "react";
import { productCategory } from "../common/helperFunction";
import HorizontalCardProduct from "../component/HorizontalCardProduct";

const ProductsPage: React.FC = () => {
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [sortOption, setSortOption] = useState<
    "lowToHigh" | "highToLow" | "none"
  >("none");

  const handleCategoryChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedCategories((prevCategories) =>
      prevCategories.includes(value)
        ? prevCategories.filter((category) => category !== value)
        : [...prevCategories, value]
    );
  };

  const handleSortChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSortOption(event.target.value as "lowToHigh" | "highToLow" | "none");
  };

  return (
    <div className="container mx-auto p-6 sm:p-8 lg:p-12">
      <h1 className="text-3xl font-bold mb-6 text-center">Products</h1>

      {/* Category Filter */}
      <div className="mb-6 flex justify-center flex-wrap gap-4">
        <div>
          <h2 className="text-lg font-medium mb-2">Filter by Category:</h2>
          {productCategory.map((category) => (
            <label key={category.value} className="block">
              <input
                type="checkbox"
                value={category.value}
                checked={selectedCategories.includes(category.value)}
                onChange={handleCategoryChange}
                className="mr-2"
              />
              {category.label}
            </label>
          ))}
        </div>

        {/* Sort by Price */}
        <div className="ml-4">
          <h2 className="text-lg font-medium mb-2">Sort by Price:</h2>
          <select
            value={sortOption}
            onChange={handleSortChange}
            className="p-3 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-customGold focus:border-customGold"
          >
            <option value="none">None</option>
            <option value="lowToHigh">Low to High</option>
            <option value="highToLow">High to Low</option>
          </select>
        </div>
      </div>

      {/* Products Display */}
      <HorizontalCardProduct
        categoryName={
          selectedCategories.length > 0 ? selectedCategories.join(",") : "all"
        }
        headerName=""
        useSlider={false}
        sortOption={sortOption}
        showAddToCart={false}
      />
    </div>
  );
};

export default ProductsPage;
