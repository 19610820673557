import React, { useContext, useEffect, useState } from "react";
import Slider from "react-slick";
import {
  horizontalCardProduct,
  product,
} from "../interface/componentInterface";
import fetchCategoryWiseProduct from "../helper/fetchCategoryWiseProduct";
import { useNavigate } from "react-router-dom";
import { productCategory, shuffleArray } from "../common/helperFunction";
import Context from "../context";
import displayCurrency from "../helper/displayCurrency";
import { NextArrow } from "./NextArrow";
import { PrevArrow } from "./PrevArrow";

const HorizontalCardProduct: React.FC<horizontalCardProduct> = ({
  categoryName,
  headerName,
  useSlider,
  sortOption = "none",
  showAddToCart = true,
}) => {
  const [data, setData] = useState<product[]>([]);
  const [loading, setLoading] = useState(true);
  const loadingList = Array(4).fill(null);
  const navigate = useNavigate();
  const context = useContext(Context);
  const locale = context?.locale || "en-IN";
  const currencyName = context?.currencyCode || "INR";
  const conversionRate = context?.conversionRate || 1;

  const setIsLoading = context?.setIsLoading;

  const fetchData = async () => {
    setLoading(true);
    const categoryProduct = await fetchCategoryWiseProduct(categoryName);

    if (categoryProduct.success) {
      const shuffleProduct = shuffleArray(categoryProduct?.data);
      let sortProd = [...shuffleProduct];
      if (sortOption !== "none") {
        sortProd = shuffleProduct.sort((a, b) =>
          sortOption === "lowToHigh"
            ? a.sellingPrice - b.sellingPrice
            : b.sellingPrice - a.sellingPrice
        );
      }
      setData(sortProd);
    }
    setLoading(false);
    if (setIsLoading) setIsLoading(false);
  };

  useEffect(() => {
    if (setIsLoading) setIsLoading(true);
    fetchData();
  }, [categoryName]);

  useEffect(() => {
    setData((prevData: product[]) => {
      let newState = [...prevData];
      let sortProd = [...newState];
      if (sortOption !== "none") {
        sortProd = newState.sort((a, b) =>
          sortOption === "lowToHigh"
            ? a.sellingPrice - b.sellingPrice
            : b.sellingPrice - a.sellingPrice
        );
      } else {
        sortProd = shuffleArray(newState);
      }
      newState = [...sortProd];
      return [...newState];
    });
  }, [sortOption]);

  const handleProductClick = (productId: string | undefined) => {
    navigate(`/product/${productId}`);
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4, //data.length < 3 ? 2 : 3, // Default number of slides to show
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 3, slidesToScroll: 1 },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2, //data.length < 3 ? 2 : 3
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1, // data.length < 2 ? 1 : 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="container mx-auto p-4">
      <div className="mb-8">
        {data.length !== 0 && (
          <h2 className="text-2xl font-bold mb-4" tabIndex={0}>
            {productCategory.find((_pc) => _pc.value === headerName)?.label}
          </h2>
        )}
        {useSlider ? (
          <Slider {...sliderSettings}>
            {loading
              ? loadingList.map((_, index) => (
                  <div
                    key={index}
                    className="border border-customGold shadow rounded p-4 flex flex-col"
                  >
                    {/* Placeholder loading state */}
                  </div>
                ))
              : data.map((product, _index) => (
                  <div key={`${product._id}_${_index}`} className="p-2">
                    <div
                      className="border border-customGold shadow rounded p-4 flex flex-col cursor-pointer"
                      role="button"
                      tabIndex={0}
                      aria-label={`View details of ${product.productName}`}
                      onClick={() => handleProductClick(product._id)}
                    >
                      <div className="aspect-w-9 aspect-h-13">
                        <img
                          src={product.productImage?.[0]?.url}
                          alt={`Image of ${product.productName}`}
                          className="w-full h-full object-cover rounded mb-4"
                        />
                      </div>
                      <h3 className="text-lg font-semibold mb-2 mt-2 truncate">
                        {product.productName}
                      </h3>
                      <p className="text-gray-600 mb-2 truncate">
                        {product.description}
                      </p>
                      <div className="flex flex-col justify-between flex-grow">
                        <div>
                          <p className="text-lg font-semibold text-green-600">
                            {displayCurrency(
                              Number(product.sellingPrice) * conversionRate,
                              locale,
                              currencyName
                            )}
                          </p>
                          {product.sellingPrice < product.price && (
                            <p className="text-sm line-through text-gray-500">
                              {displayCurrency(
                                Number(product.price) * conversionRate,
                                locale,
                                currencyName
                              )}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
          </Slider>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {loading
              ? loadingList.map((_, index) => (
                  <div
                    key={index}
                    className="border border-customGold shadow rounded p-4 flex flex-col"
                  >
                    {/* Placeholder loading state */}
                  </div>
                ))
              : data.map((product, _index) => (
                  <div
                    key={`${product._id}_${_index}`}
                    className="border border-customGold shadow rounded p-4 flex flex-col cursor-pointer"
                    onClick={() => handleProductClick(product._id)}
                    role="button"
                    tabIndex={0}
                    aria-label={`View details of ${product.productName}`}
                  >
                    <div className="aspect-w-9 aspect-h-13">
                      <img
                        src={product.productImage?.[0]?.url}
                        alt={`Image of ${product.productName}`}
                        className="w-full h-full object-cover rounded mb-4"
                      />
                    </div>
                    <h3 className="text-lg font-semibold mb-2 mt-2 truncate">
                      {product.productName}
                    </h3>
                    <p className="text-gray-600 mb-2 truncate">
                      {product.description}
                    </p>
                    <div className="flex flex-col justify-between flex-grow">
                      <div>
                        <p className="text-lg font-semibold text-green-600">
                          {displayCurrency(
                            Number(product.sellingPrice) * conversionRate,
                            locale,
                            currencyName
                          )}
                        </p>
                        {product.sellingPrice < product.price && (
                          <p className="text-sm line-through text-gray-500">
                            {displayCurrency(
                              Number(product.price) * conversionRate,
                              locale,
                              currencyName
                            )}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default HorizontalCardProduct;
