import React, { useContext, useState } from "react";
import SummaryAPI from "../common";
import { customToast } from "../common/helperFunction";
import Context from "../context";

// Function to sanitize message by removing HTML tags
const sanitizeMessage = (input: string): string => {
  return input.replace(/<\/?[^>]+(>|$)/g, ""); // Strips HTML tags
};

const isMessageSafe = (message: string): boolean => {
  const suspiciousPatterns =
    /<script.*?>|<\/script>|<iframe.*?>|<\/iframe>|onerror=|onload=|javascript:/gi;
  return !suspiciousPatterns.test(message);
};

const ContactUs: React.FC = () => {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  // const [isLoading, setIsLoading] = useState<boolean>(false);
  const context = useContext(Context);
  const isLoading = context?.isLoading;
  const setIsLoading = context?.setIsLoading;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Basic Email and Message Length Validation
    if (!/^\S+@\S+\.\S+$/.test(email)) {
      customToast("Please enter a valid email address", "error");
      return;
    }

    if (message.length < 10 || message.length > 500) {
      customToast("Message should be between 10 and 500 characters", "error");
      return;
    }

    // Sanitize and Check Message for Suspicious Content
    const sanitizedMessage = sanitizeMessage(message);
    if (!isMessageSafe(sanitizedMessage)) {
      customToast("Message contains unsafe content", "error");
      return;
    }

    if (setIsLoading) setIsLoading(true);

    try {
      // Notify support team
      const supportResponse = await fetch(SummaryAPI.sendmail.URL, {
        method: SummaryAPI.sendmail.method,
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          to: "sabrcollection29@gmail.com", // Replace with your support email
          subject: `New Contact Us Message from ${name}`,
          bodyText: `From: ${name}\nEmail: ${email}\nMessage: ${sanitizedMessage}`,
          bodyHtml: `<strong>From:</strong> ${name}<br><strong>Email:</strong> ${email}<br><strong>Message:</strong><p>${sanitizedMessage}</p>`,
        }),
      });

      const supportData = await supportResponse.json();
      if (!supportData.success) {
        customToast(
          "Failed to notify support. Please try again later.",
          "error"
        );
        return;
      }

      // Send confirmation to user
      const response = await fetch(SummaryAPI.sendmail.URL, {
        method: SummaryAPI.sendmail.method,
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          to: email,
          subject: `Thank you for contacting us, ${name}`,
          bodyText: `Hello ${name},\n\nThank you for reaching out. We have received your message and will get back to you shortly.\n\nBest Regards,\nSABR`,
          bodyHtml: `<p>Hello ${name},</p><p>Thank you for reaching out. We have received your message and will get back to you shortly.</p><p>Best Regards,<br>SABR</p>`,
        }),
      });

      const data = await response.json();
      if (data.success) {
        customToast("Email sent successfully!", "success");
        setName("");
        setEmail("");
        setMessage("");
      } else {
        customToast("Failed to send email. Please try again later.", "error");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      customToast("An error occurred. Please try again later.", "error");
    } finally {
      if (setIsLoading) setIsLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen">
      <div className="w-full max-w-2xl p-8 shadow-lg rounded-lg">
        <h2 className="text-3xl font-bold text-center mb-6">Contact Us</h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="name" className="block text-sm font-medium">
              Name
            </label>
            <input
              type="text"
              id="name"
              className="bg-black mt-1 block w-full p-3 border placeholder-customGold border-customGold rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-customGold focus:border-customGold"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Your Name"
              required
            />
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium">
              Email
            </label>
            <input
              type="email"
              id="email"
              className="bg-black mt-1 block w-full p-3 border placeholder-customGold border-customGold rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-customGold focus:border-customGold"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Your Email"
              required
            />
          </div>
          <div>
            <label htmlFor="message" className="block text-sm font-medium ">
              Message
            </label>
            <textarea
              id="message"
              className="bg-black mt-1 block w-full p-3 border placeholder-customGold border-customGold rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-customGold focus:border-customGold"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Your Message"
              rows={5}
              maxLength={500}
              required
            ></textarea>
          </div>
          <div>
            <button
              type="submit"
              disabled={isLoading}
              className={`w-full py-3 px-4 bg-black text-customGold rounded-md border-2 border-customGold hover:bg-customGoldHover hover:text-black hover:border-black ${
                isLoading ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {isLoading ? "Sending..." : "Send Message"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
