import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { IPlaceOrder } from "../interface/pageInterface";
import SummaryAPI from "../common";
import displayCurrency from "../helper/displayCurrency";
import Context from "../context";

const OrderPage: React.FC = () => {
  const { id } = useParams<{ id: string }>(); // Destructure id from useParams
  const [order, setOrder] = useState<IPlaceOrder | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const context = useContext(Context);
  const setIsLoading = context?.setIsLoading;
  const locale = context?.locale || "en-IN";
  const currencyName = context?.currencyCode || "INR";
  const conversionRate = context?.conversionRate || 1;

  // Fetch Orders
  const fetchOrder = async (orderDetailId: string) => {
    try {
      setLoading(true);
      if (setIsLoading) setIsLoading(true);
      const response = await fetch(
        `${SummaryAPI.getOrderDetails.URL}?orderId=${orderDetailId}`,
        {
          method: "GET",
          credentials: "include",
        }
      );

      const orderResponse = await response.json();

      if (orderResponse.success) {
        setOrder(orderResponse.data[0]);
      } else {
        setOrder(null);
      }
    } catch (err) {
      setOrder(null);
    } finally {
      setLoading(false);
    }
    if (setIsLoading) setIsLoading(false);
  };

  useEffect(() => {
    if (id) {
      if (setIsLoading) setIsLoading(true);
      fetchOrder(id);
    }
  }, [id]);

  if (loading) {
    return <div className="container mx-auto px-4 py-6">Loading...</div>;
  }

  if (!order) {
    return <div className="container mx-auto px-4 py-6">No Orders yet</div>;
  }

  return (
    <div className="container mx-auto px-4 py-6">
      <h1 className="text-3xl font-bold mb-4">Order Summary</h1>

      <div className="p-4 rounded-lg shadow-md border border-customGold">
        {/* Order Info */}
        <div className="mb-4 pb-4 border-b border-customGold">
          <h2 className="text-xl font-semibold break-words">Order ID:</h2>
          <p className="break-words">{order._id}</p>

          <h2 className="text-xl font-semibold break-words">User ID:</h2>
          <p className="break-words">{order.userId}</p>

          <p>Payment Mode: {order.paymentMode}</p>

          <p>
            Payment Status:{" "}
            <span
              className={`font-semibold ${
                order.paymentStatus === "Completed"
                  ? "text-green-600"
                  : order.paymentStatus === "Pending"
                  ? "text-yellow-600"
                  : "text-red-600"
              }`}
            >
              {order.paymentStatus}
            </span>
          </p>

          <p>
            Delivery Status:{" "}
            <span
              className={`font-semibold ${
                order.deliveryStatus === "Delivered"
                  ? "text-green-600"
                  : order.deliveryStatus === "Shipped"
                  ? "text-blue-600"
                  : "text-yellow-600"
              }`}
            >
              {order.deliveryStatus}
            </span>
          </p>
        </div>

        {/* Customer Info (Shipping Address) */}
        <div className="mb-4 pb-4 border-b border-customGold">
          <h2 className="text-xl font-semibold mb-2">Shipping Address</h2>
          <p>
            <span className="font-semibold">Name: </span>
            {order.customerName}
          </p>
          <p>
            <span className="font-semibold">Mobile: </span>
            {order.customerMobile}
          </p>
          <p>
            <span className="font-semibold">Address: </span>
            {order.customerAddress}
          </p>
        </div>

        {/* Order Items */}
        <h2 className="text-xl font-semibold mb-4">Items</h2>
        <div className="space-y-4">
          {order.items.map((item) => (
            <div
              key={item.id}
              className="flex flex-col exsm:flex-row items-center p-4 rounded-lg shadow-exsm border border-customGold"
            >
              <div className="w-full exsm:w-28 min-w-[80px] flex justify-center p-4">
                <div className="aspect-[9/13] w-full">
                  <img
                    src={item.imageUrl}
                    alt={item.name}
                    className="object-cover w-full h-full rounded-md"
                  />
                </div>
              </div>

              <div className="ml-0 exsm:ml-4 flex-1 text-center exsm:text-left">
                <h3 className="text-lg font-medium">{item.name}</h3>
                <p>Size: {item.size}</p>
                <p>Quantity: {item.quantity}</p>
              </div>
              <div className="text-lg font-semibold mt-2 exsm:mt-0">
                {displayCurrency(
                  Number(item.price * item.quantity) * conversionRate,
                  locale,
                  currencyName
                )}
              </div>
            </div>
          ))}
        </div>

        {/* Total Amount */}
        <div className="mt-6 pt-4 border-t border-customGold text-right">
          <p className="text-xl font-semibold">
            Total:{" "}
            {displayCurrency(
              Number(
                order.items
                  .reduce(
                    (total, item) => total + item.price * item.quantity,
                    0
                  )
                  .toFixed(2)
              ) * conversionRate,
              locale,
              currencyName
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default OrderPage;
