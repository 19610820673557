const url = `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME_CLOUDINARY}/image/upload`;

const uploadImage = async (image: File): Promise<any> => {
  const formData = new FormData();
  formData.append("file", image);
  formData.append("upload_preset", "sabr_product");

  try {
    const response = await fetch(url, {
      method: "post",
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }
    const data = await response.json();
    // console.log("Response Data:", data); // Log the data to check the URL
    return data;
  } catch (error) {
    console.error("Upload failed:", error);
    throw error;
  }
};

export default uploadImage;
