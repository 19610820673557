import { useContext, useEffect, useState } from "react";
import SummaryAPI from "../common";
import { Link } from "react-router-dom";
import { product } from "../interface/componentInterface";
import { customToast, productCategory } from "../common/helperFunction";
import Context from "../context";

const GridImage = () => {
  const [categoryProduct, setCategoryProduct] = useState<product[]>([]);
  const [loading, setLoading] = useState(true);

  const categoryLoading = new Array(productCategory.length).fill(null);

  const context = useContext(Context);
  const setIsLoading = context?.setIsLoading;

  const fetchCategoryProduct = async () => {
    setLoading(true);
    const response = await fetch(SummaryAPI.getProductCategory.URL);
    const dataResponse = await response.json();
    setLoading(false);

    if (dataResponse.success) {
      setCategoryProduct(dataResponse.data);
    }

    if (dataResponse.error) {
      customToast(dataResponse.message, "error");
    }
    if (setIsLoading) setIsLoading(false);
  };

  useEffect(() => {
    if (setIsLoading) setIsLoading(true);
    fetchCategoryProduct();
  }, []);

  return (
    <div className="container mx-auto p-4">
      <div
        className="grid gap-4
                    sm:grid-cols-2 
                    md:grid-cols-3 
                    lg:grid-cols-4 
                    justify-center" // Centers the entire grid content
        style={{ maxWidth: "1200px" }}
      >
        {loading
          ? categoryLoading.map((el, index) => (
              <div
                className="aspect-[9/13] bg-slate-200 animate-pulse rounded-lg"
                key={"categoryLoading" + index}
              ></div>
            ))
          : categoryProduct.map((product, index) => (
              <Link
                to={"/productCategory/" + product?.category}
                className="cursor-pointer"
                key={product?.category}
              >
                <div className="relative aspect-[9/13] rounded-lg shadow-lg overflow-hidden transition-transform duration-300 hover:scale-[1.02]">
                  <img
                    src={product?.productImage[0].url}
                    alt={product?.category}
                    className="object-cover w-full h-full"
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <p className="text-white text-sm md:text-base text-center">
                      {
                        productCategory.find(
                          (_pc) => _pc.value === product?.category
                        )?.label
                      }
                    </p>
                  </div>
                </div>
              </Link>
            ))}
      </div>
    </div>
  );
};

export default GridImage;
