import { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaShoppingCart, FaUser } from "react-icons/fa";
import Logo from "./Logo";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import SummaryAPI from "../common";
import { setUserDetail } from "../store/userSlice";
import {
  customToast,
  getLocaleDetails,
  localeOptions,
} from "../common/helperFunction";
import ROLE from "../common/role";
import Context from "../context";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [isLocaleMenuOpen, setIsLocaleMenuOpen] = useState(false);

  const userDetails = useSelector((state: RootState) => state?.user.user);
  const dispatch = useDispatch();
  const context = useContext(Context);
  const navigate = useNavigate();
  const setLocale = context?.setLocale;
  const locale = context?.locale || "en-IN";

  const setIsLoading = context?.setIsLoading;

  // Memoizing user to avoid recalculating on every render
  const user = useMemo(() => {
    return {
      name: userDetails?._id
        ? `${userDetails.firstName} ${userDetails.lastName}`
        : null,
      role: userDetails?.role || null,
    };
  }, [userDetails]);

  const cartItemCount = context?.cartProductCount || 0;

  const toggleMenu = () => {
    setIsProfileMenuOpen(false); // Ensure profile menu is closed
    setIsLocaleMenuOpen(false);
    setIsMenuOpen((prev) => !prev);
  };

  const toggleProfileMenu = () => {
    setIsLocaleMenuOpen(false);
    setIsProfileMenuOpen((prev) => !prev);
  };

  const handleLogout = async () => {
    if (setIsLoading) setIsLoading(true);
    try {
      const response = await fetch(SummaryAPI.logoutUser.URL, {
        method: SummaryAPI.logoutUser.method,
        credentials: "include",
      });

      const data = await response.json();

      if (data.success) {
        customToast(data.message, "success");
        dispatch(setUserDetail(null));
        setIsMenuOpen(false);
        setIsProfileMenuOpen(false);
        context?.fetchUserAddToCart();
        navigate("/");
      } else if (data.error) {
        customToast(data.message, "error");
      }
    } catch (error) {
      console.error("Logout error:", error);
      customToast("An unexpected error occurred.", "error");
    }
    if (setIsLoading) setIsLoading(false);
  };

  const handleHeaderCollapse = () => {
    setIsMenuOpen(false);
    setIsProfileMenuOpen(false);
    setIsLocaleMenuOpen(false);
  };

  const changeLocale = (newLocale: string) => {
    if (setLocale) {
      setLocale(newLocale);
    }
    setIsLocaleMenuOpen(false);
  };

  const toggleLocaleMenu = () => {
    setIsProfileMenuOpen(false);
    setIsLocaleMenuOpen((prev) => !prev);
  };
  // useEffect to navigate only when userDetails.role changes
  // useEffect(() => {
  //   if (userDetails?.role !== ROLE.ADMIN) {
  //     navigate("/");
  //   }
  // }, [userDetails?.role, navigate]); // Only triggers when role or navigate changes

  return (
    <nav className="bg-black p-4 fixed top-0 w-full z-20 shadow-customShadow-md">
      <div className="container-fluid mx-auto flex items-center justify-between">
        {/* Logo */}
        <div className="flex items-center space-x-4">
          <div className="text-customGold text-lg font-bold">
            <Link to="/" onClick={handleHeaderCollapse}>
              <Logo width={151} height={50} />
            </Link>
          </div>
        </div>

        {/* Navigation Items */}
        <div className="hidden md:flex space-x-4 items-center">
          <Link
            to="/"
            className="text-customGold hover:text-customGoldHover"
            onClick={handleHeaderCollapse}
          >
            Home
          </Link>
          <Link
            to="/products"
            className="text-customGold hover:text-customGoldHover"
            onClick={handleHeaderCollapse}
          >
            Products
          </Link>
          <Link
            to="/aboutUs"
            className="text-customGold hover:text-customGoldHover"
            onClick={handleHeaderCollapse}
          >
            About Us
          </Link>
          <Link
            to="/contactUs"
            className="text-customGold hover:text-customGoldHover"
            onClick={handleHeaderCollapse}
          >
            Contact Us
          </Link>
        </div>

        {/* Icons */}
        <div className="hidden md:flex space-x-4 items-center relative">
          <Link
            to="/cart"
            className="text-customGold hover:text-customGoldHover relative"
            onClick={handleHeaderCollapse}
          >
            <FaShoppingCart />
            <div className="text-customGold w-3 h-3 rounded-full p-1 flex items-center justify-center absolute -top-2 -right-2">
              <p className="text-xs">{cartItemCount}</p>
            </div>
          </Link>

          {/* Locale Dropdown */}
          <div className="relative">
            <button
              onClick={toggleLocaleMenu}
              className="text-customGold hover:text-customGoldHover"
            >
              {getLocaleDetails(locale)?.label}
            </button>
            {isLocaleMenuOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-black text-customGold rounded shadow-lg">
                {localeOptions.map((_data, _index) => {
                  return (
                    <button
                      key={`${_data.label}_${_index}`}
                      onClick={() => changeLocale(_data.value)}
                      className="block w-full text-left px-4 py-2 hover:bg-gray-700"
                    >
                      {_data.label}
                    </button>
                  );
                })}
                {/* <button
                  onClick={() => changeLocale("en-IN")}
                  className="block w-full text-left px-4 py-2 hover:bg-gray-700"
                >
                  🇮🇳 India
                </button>
                <button
                  onClick={() => changeLocale("ar-SA")}
                  className="block w-full text-left px-4 py-2 hover:bg-gray-700"
                >
                  🇸🇦 Saudi Arabia
                </button>
                <button
                  onClick={() => changeLocale("en-IN")}
                  className="block w-full text-left px-4 py-2 hover:bg-gray-700"
                >
                  🌍 Other
                </button> */}
              </div>
            )}
          </div>

          {/* Profile Icon */}
          <div className="relative">
            <button
              onClick={toggleProfileMenu}
              className="text-customGold hover:text-customGoldHover"
            >
              <FaUser />
            </button>

            {/* Profile Menu Popup */}
            {isProfileMenuOpen && (
              <div className="absolute right-0 top-10 bg-black text-customGold rounded shadow-customShadow-md p-2 w-48">
                {user.name ? (
                  <>
                    <div className="p-2">
                      <p className="text-customGold font-medium">{user.name}</p>
                    </div>
                    <Link
                      to="/"
                      className="block px-4 py-2 text-sm text-customGold hover:customGoldHover rounded hover:bg-gray-700"
                      onClick={handleHeaderCollapse}
                    >
                      Profile
                    </Link>
                    <Link
                      to="/myOrders"
                      className="block px-4 py-2 text-sm text-customGold hover:customGoldHover rounded hover:bg-gray-700"
                      onClick={handleHeaderCollapse}
                    >
                      My Orders
                    </Link>
                    {user.role === ROLE.ADMIN && (
                      <Link
                        to="/adminPanel/allProduct"
                        className="block px-4 py-2 text-sm text-customGold hover:customGoldHover rounded hover:bg-gray-700"
                        onClick={handleHeaderCollapse}
                      >
                        Admin Panel
                      </Link>
                    )}
                    <button
                      onClick={handleLogout}
                      className="block w-full text-left px-4 py-2 text-sm text-customGold hover:customGoldHover rounded hover:bg-gray-700"
                    >
                      Logout
                    </button>
                  </>
                ) : (
                  <Link
                    to="/login"
                    className="block px-4 py-2 text-sm bg-black text-customGold hover:text-customGoldHover rounded hover:bg-gray-700"
                    onClick={handleHeaderCollapse}
                  >
                    Login
                  </Link>
                )}
              </div>
            )}
          </div>
        </div>

        {/* Mobile Menu Button */}
        <button onClick={toggleMenu} className="md:hidden text-customGold">
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </button>
      </div>

      {/* Mobile Menu */}
      <div
        className={`md:hidden bg-black p-4 ${isMenuOpen ? "block" : "hidden"}`}
      >
        <div className="flex flex-col space-y-4">
          <Link
            to="/"
            className="text-customGold hover:text-customGoldHover"
            onClick={handleHeaderCollapse}
          >
            Home
          </Link>
          <Link
            to="/products"
            className="text-customGold hover:text-customGoldHover"
            onClick={handleHeaderCollapse}
          >
            Products
          </Link>
          <Link
            to="/aboutUs"
            className="text-customGold hover:text-customGoldHover"
            onClick={handleHeaderCollapse}
          >
            About Us
          </Link>
          <Link
            to="/contactUs"
            className="text-customGold hover:text-customGoldHover"
            onClick={handleHeaderCollapse}
          >
            Contact Us
          </Link>
          <div className="flex space-x-4">
            <Link
              to="/cart"
              className="text-customGold hover:text-customGoldHover relative"
              onClick={handleHeaderCollapse}
            >
              <FaShoppingCart />
              <div className="text-customGold w-3 h-3 rounded-full p-1 flex items-center justify-center absolute -top-2 -right-2">
                <p className="text-xs">{cartItemCount}</p>
              </div>
            </Link>

            {/* Locale Dropdown */}
            <div className="relative">
              <button
                onClick={toggleLocaleMenu}
                className="text-customGold hover:text-customGoldHover"
              >
                {getLocaleDetails(locale)?.label}
              </button>
              {isLocaleMenuOpen && (
                <div className="absolute left-0 mt-2 w-48 bg-black text-customGold rounded shadow-lg">
                  {localeOptions.map((_data, _index) => {
                    return (
                      <button
                        key={`${_data.label}_${_index}`}
                        onClick={() => changeLocale(_data.value)}
                        className="block w-full text-left px-4 py-2 hover:bg-gray-700"
                      >
                        {_data.label}
                      </button>
                    );
                  })}
                </div>
              )}
            </div>
            <div className="relative">
              <button
                onClick={toggleProfileMenu}
                className="text-customGold hover:text-customGoldHover"
              >
                <FaUser />
              </button>
              {isProfileMenuOpen && (
                <div className="absolute left-0 top-10 bg-black text-customGold rounded shadow-customShadow-md p-2 w-48">
                  {user.name ? (
                    <>
                      <div className="p-2">
                        <p className="text-customGold font-medium">
                          {user.name}
                        </p>
                      </div>
                      <Link
                        to="/"
                        className="block px-4 py-2 text-sm text-customGold hover:customGoldHover rounded hover:bg-gray-700"
                        onClick={handleHeaderCollapse}
                      >
                        Profile
                      </Link>
                      <Link
                        to="/myOrders"
                        className="block px-4 py-2 text-sm text-customGold hover:customGoldHover rounded hover:bg-gray-700"
                        onClick={handleHeaderCollapse}
                      >
                        My Orders
                      </Link>
                      {user.role === ROLE.ADMIN && (
                        <Link
                          to="/adminPanel/allProduct"
                          className="block px-4 py-2 text-sm text-customGold hover:customGoldHover rounded hover:bg-gray-700"
                          onClick={handleHeaderCollapse}
                        >
                          Admin Panel
                        </Link>
                      )}
                      <button
                        onClick={handleLogout}
                        className="block w-full text-left px-4 py-2 text-sm text-customGold hover:customGoldHover rounded hover:bg-gray-700"
                      >
                        Logout
                      </button>
                    </>
                  ) : (
                    <Link
                      to="/login"
                      className="block px-4 py-2 text-sm bg-black text-customGold hover:text-customGoldHover rounded hover:bg-gray-700"
                      onClick={handleHeaderCollapse}
                    >
                      Login
                    </Link>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
