import { productCategory } from "../common/helperFunction";
import BannerProduct from "../component/BannerProduct";
import GridImage from "../component/GridImage";
import HorizontalCardProduct from "../component/HorizontalCardProduct";

const Home = () => {
  return (
    <div>
      <BannerProduct />
      <GridImage />
      {productCategory.map((_prodDetails, _index) => {
        return (
          <HorizontalCardProduct
            key={`${_prodDetails.value}_${_index}`}
            headerName={_prodDetails.label}
            categoryName={_prodDetails.value}
            useSlider={false}
          />
        );
      })}
    </div>
  );
};

export default Home;
