const displayCurrency = (num: number, locale: string, currencyName: string) => {
  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currencyName,
    minimumFractionDigits: 2,
  });

  return formatter.format(num);
};
export default displayCurrency;
