import { createContext } from "react";
interface AppContextType {
  fetchUserDetails: () => Promise<void>;
  cartProductCount: number;
  fetchUserAddToCart: () => Promise<void>;
  currencyCode: string;
  locale: string;
  conversionRate: number;
  isLoading: boolean;
  setIsLoading: any;
  setLocale: any;
}
const Context = createContext<AppContextType | null>(null);
export default Context;
