import { ArrowProps } from "../interface/componentInterface";
import { RiArrowLeftWideLine } from "react-icons/ri";

export const PrevArrow: React.FC<ArrowProps> = ({ onClick }) => (
  <div
    onClick={onClick}
    className="absolute top-1/2 left-0 transform -translate-y-1/2 p-2 cursor-pointer bg-gray-800 text-customGold rounded-full z-10"
    style={{
      fontSize: "1.5rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
    aria-label="Previous Slide"
  >
    <RiArrowLeftWideLine className="w-6 h-6 sm:w-8 sm:h-8" />
  </div>
);
