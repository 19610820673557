import SummaryAPI from "../common";

const fetchCategoryWiseProduct = async (category: string) => {
  const fetchData = await fetch(SummaryAPI.categoryWiseProduct.URL, {
    method: SummaryAPI.categoryWiseProduct.method,
    headers: { "content-type": "application/json" },
    body: JSON.stringify({
      category: category,
    }),
  });

  const dataResponse = await fetchData.json();
  return dataResponse;
};

export default fetchCategoryWiseProduct;
