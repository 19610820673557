import React from "react";
import { logoProps } from "../interface/componentInterface";

const Logo: React.FC<logoProps> = (props) => {
  const { width, height } = props;
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
    >
      <path
        d="M0 0 C49.83 0 99.66 0 151 0 C151 16.17 151 32.34 151 49 C101.17 49 51.34 49 0 49 C0 32.83 0 16.66 0 0 Z "
        fill="#040301"
        transform="translate(0,0)"
      />
      <path
        d="M0 0 C7.3321875 -0.0928125 7.3321875 -0.0928125 14.8125 -0.1875 C16.35139893 -0.21481201 17.89029785 -0.24212402 19.47583008 -0.27026367 C20.68650146 -0.27775635 21.89717285 -0.28524902 23.14453125 -0.29296875 C25.00621948 -0.31653442 25.00621948 -0.31653442 26.90551758 -0.34057617 C30 0 30 0 31.91015625 1.34033203 C33.58216957 3.88655662 33.28591861 6.0625549 33.1875 9.0625 C33.14689453 10.65771484 33.14689453 10.65771484 33.10546875 12.28515625 C33.07066406 13.18105469 33.03585938 14.07695312 33 15 C33 16.64519917 33.06185105 18.29028156 33.140625 19.93359375 C33.19476563 21.66802734 33.19476563 21.66802734 33.25 23.4375 C33.29640625 24.59121094 33.3428125 25.74492188 33.390625 26.93359375 C33 30 33 30 31.39648438 31.67333984 C28.2064106 33.43931997 25.90133627 33.34080706 22.265625 33.29296875 C21.09805664 33.28547607 19.93048828 33.2779834 18.72753906 33.27026367 C17.24995117 33.24295166 15.77236328 33.21563965 14.25 33.1875 C9.5475 33.125625 4.845 33.06375 0 33 C0 22.11 0 11.22 0 0 Z "
        fill="#B9962E"
        transform="translate(77,7)"
      />
      <path
        d="M0 0 C7.05375 -0.12375 7.05375 -0.12375 14.25 -0.25 C15.72758789 -0.28641602 17.20517578 -0.32283203 18.72753906 -0.36035156 C19.89510742 -0.3703418 21.06267578 -0.38033203 22.265625 -0.390625 C23.45961914 -0.41157227 24.65361328 -0.43251953 25.88378906 -0.45410156 C29.61864973 0.09015109 30.76385491 1.01486635 33 4 C33.79965343 8.05893053 33.6559599 11.92164062 33 16 C30.38399215 19.30848052 28.7612874 19.82763304 24.6875 20.75 C23.3571875 20.87375 23.3571875 20.87375 22 21 C24.51830747 24.12270126 25.7261549 24.94523098 29.75 25.75 C30.8225 25.8325 31.895 25.915 33 26 C33 28.31 33 30.62 33 33 C29.4153892 33.85649993 26.57889128 33.94475283 23 33 C20.03905636 30.81035595 17.6128943 28.12399957 15.20019531 25.35644531 C11.95304291 21.66840324 11.09767431 21.01953486 6 20 C6 24.29 6 28.58 6 33 C4.02 33 2.04 33 0 33 C0 22.11 0 11.22 0 0 Z "
        fill="#BB972F"
        transform="translate(114,7)"
      />
      <path
        d="M0 0 C1.19302734 -0.01998047 2.38605469 -0.03996094 3.61523438 -0.06054688 C4.76185547 -0.05990234 5.90847656 -0.05925781 7.08984375 -0.05859375 C8.13906006 -0.06044678 9.18827637 -0.0622998 10.26928711 -0.06420898 C13.0625 0.4375 13.0625 0.4375 15.41137695 2.04760742 C17.61997967 5.24441535 17.52092838 7.30708229 17.453125 11.171875 C17.44023438 12.46738281 17.42734375 13.76289063 17.4140625 15.09765625 C17.38064366 16.46094824 17.34678391 17.82422949 17.3125 19.1875 C17.29242707 20.56638064 17.27417767 21.94528912 17.2578125 23.32421875 C17.21065448 26.69601744 17.14484538 30.06640687 17.0625 33.4375 C14.7525 33.4375 12.4425 33.4375 10.0625 33.4375 C10.0625 29.1475 10.0625 24.8575 10.0625 20.4375 C3.4625 20.4375 -3.1375 20.4375 -9.9375 20.4375 C-9.9375 24.7275 -9.9375 29.0175 -9.9375 33.4375 C-11.9175 33.4375 -13.8975 33.4375 -15.9375 33.4375 C-16.01169134 29.22000781 -16.0661204 25.00286189 -16.10229492 20.78491211 C-16.11737589 19.35027448 -16.1378438 17.91568292 -16.16381836 16.48120117 C-16.20023437 14.41834141 -16.21723243 12.35609142 -16.23046875 10.29296875 C-16.2461792 9.05184326 -16.26188965 7.81071777 -16.27807617 6.53198242 C-15.9375 3.4375 -15.9375 3.4375 -14.78417969 1.53613281 C-10.53225793 -0.9934346 -4.79569098 -0.02840743 0 0 Z "
        fill="#BA972E"
        transform="translate(56.9375,6.5625)"
      />
      <path
        d="M0 0 C1.94326172 0.01933594 1.94326172 0.01933594 3.92578125 0.0390625 C5.28907324 0.07248134 6.65235449 0.10634109 8.015625 0.140625 C9.39450564 0.16069793 10.77341412 0.17894733 12.15234375 0.1953125 C15.52414244 0.24247052 18.89453187 0.30827962 22.265625 0.390625 C22.265625 2.370625 22.265625 4.350625 22.265625 6.390625 C13.685625 6.390625 5.105625 6.390625 -3.734375 6.390625 C-3.734375 8.700625 -3.734375 11.010625 -3.734375 13.390625 C-3.08887695 13.36967773 -2.44337891 13.34873047 -1.77832031 13.32714844 C1.15283568 13.24388295 4.08374277 13.19193294 7.015625 13.140625 C8.03140625 13.10710938 9.0471875 13.07359375 10.09375 13.0390625 C17.53365385 12.94116903 17.53365385 12.94116903 20.9375 15.03125 C22.60650872 17.9961949 22.79958039 19.9950859 22.765625 23.390625 C22.7759375 24.421875 22.78625 25.453125 22.796875 26.515625 C22.12512154 30.15099667 21.17356521 31.14395872 18.265625 33.390625 C15.14941406 33.84472656 15.14941406 33.84472656 11.53125 33.78125 C10.23574219 33.76835938 8.94023437 33.75546875 7.60546875 33.7421875 C6.24217676 33.70876866 4.87889551 33.67490891 3.515625 33.640625 C2.13674436 33.62055207 0.75783588 33.60230267 -0.62109375 33.5859375 C-3.99289244 33.53877948 -7.36328187 33.47297038 -10.734375 33.390625 C-10.734375 31.080625 -10.734375 28.770625 -10.734375 26.390625 C-2.154375 26.390625 6.425625 26.390625 15.265625 26.390625 C15.265625 24.410625 15.265625 22.430625 15.265625 20.390625 C14.29737793 20.42990112 14.29737793 20.42990112 13.30957031 20.4699707 C10.37850383 20.57404939 7.44782591 20.6389831 4.515625 20.703125 C2.99195313 20.7659668 2.99195313 20.7659668 1.4375 20.83007812 C-6.0424058 20.95310289 -6.0424058 20.95310289 -9.40625 18.21875 C-10.83820701 15.1695239 -11.27171676 13.18421608 -11.234375 9.828125 C-11.2446875 8.89871094 -11.255 7.96929687 -11.265625 7.01171875 C-9.98336837 0.68529082 -5.82845433 -0.10225358 0 0 Z "
        fill="#B7952E"
        transform="translate(14.734375,6.609375)"
      />
      <path
        d="M0 0 C6.93 0 13.86 0 21 0 C21 2.31 21 4.62 21 7 C14.07 7 7.14 7 0 7 C0 4.69 0 2.38 0 0 Z "
        fill="#141004"
        transform="translate(120,13)"
      />
      <path
        d="M0 0 C6.6 0 13.2 0 20 0 C20 2.31 20 4.62 20 7 C13.4 7 6.8 7 0 7 C0 4.69 0 2.38 0 0 Z "
        fill="#0D0A03"
        transform="translate(84,13)"
      />
      <path
        d="M0 0 C6.6 0 13.2 0 20 0 C20 2.31 20 4.62 20 7 C13.4 7 6.8 7 0 7 C0 4.69 0 2.38 0 0 Z "
        fill="#0C0A03"
        transform="translate(47,13)"
      />
      <path
        d="M0 0 C6.6 0 13.2 0 20 0 C20 1.98 20 3.96 20 6 C13.4 6 6.8 6 0 6 C0 4.02 0 2.04 0 0 Z "
        fill="#020100"
        transform="translate(84,27)"
      />
    </svg>
  );
};

export default Logo;
