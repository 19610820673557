// import React, { useState, useEffect } from "react";
// import "../style/WordTyping.css";
// import { wordTyping } from "../interface/componentInterface";

// const WordTyping: React.FC<wordTyping> = (props) => {
//   const { onChange } = props;
//   const [fixedText, setFixedText] = useState("");
//   const [isVisible, setIsVisible] = useState(true);
//   const word = "SABR";

//   useEffect(() => {
//     const typer = async (sentence: string, delay = 300) => {
//       await setTimeout(() => {}, 1000);
//       let currentText = "";
//       for (let char of sentence) {
//         currentText += char;
//         setFixedText(currentText);
//         await wait(delay);
//       }
//       setTimeout(() => setIsVisible(false), 1000); // Hide text after a delay once typing completes
//     };

//     const wait = (delay: number) =>
//       new Promise((resolve) => setTimeout(resolve, delay));

//     typer(word);
//   }, []);

//   useEffect(() => {
//     onChange(isVisible);
//   }, [isVisible]);

//   return (
//     <div className="typing-container">
//       <p
//         className="fixed-text"
//         style={{ display: isVisible ? "block" : "none" }}
//       >
//         {fixedText}
//         {/* <span className="cursor">|</span> */}
//       </p>
//     </div>
//   );
// };

// export default WordTyping;

import React, { useState, useEffect } from "react";
import "../style/WordTyping.css";
import { wordTyping } from "../interface/componentInterface";

const WordTyping: React.FC<wordTyping> = (props) => {
  const { onChange } = props;
  const [visibleIndex, setVisibleIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  const letterUrls = [
    `${process.env.PUBLIC_URL}/S.svg`,
    `${process.env.PUBLIC_URL}/A.svg`,
    `${process.env.PUBLIC_URL}/B.svg`,
    `${process.env.PUBLIC_URL}/R.svg`,
  ];

  useEffect(() => {
    const typeLetters = async () => {
      await wait(1500);
      for (let i = 0; i < letterUrls.length; i++) {
        setVisibleIndex(i + 1);
        await wait(400);
      }
      setTimeout(() => setIsVisible(false), 1000);
    };

    const wait = (delay: number) =>
      new Promise((resolve) => setTimeout(resolve, delay));

    typeLetters();
  }, []);

  useEffect(() => {
    onChange(isVisible);
  }, [isVisible]);

  return (
    <div className="typing-container">
      <div
        className="fixed-text"
        style={{ display: isVisible ? "flex" : "none" }}
      >
        {letterUrls.slice(0, visibleIndex).map((url, index) => (
          <img
            key={index}
            src={url}
            alt={`Letter ${index}`}
            className={`transition-opacity duration-1000 ease-in transform ${
              index < visibleIndex
                ? "opacity-100 translate-y-0"
                : "opacity-0 translate-y-5"
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export default WordTyping;
