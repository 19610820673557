import React, { useState, useEffect, useContext } from "react";
import SummaryAPI from "../common";
import { customToast } from "../common/helperFunction";
import Context from "../context";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { cartItem } from "../interface/componentInterface";
import displayCurrency from "../helper/displayCurrency";
import { FaMinus, FaPlus } from "react-icons/fa";

const Cart: React.FC = () => {
  const [cartItems, setCartItems] = useState<cartItem[]>([]);
  const context = useContext(Context);
  const locale = context?.locale || "en-IN";
  const currencyName = context?.currencyCode || "INR";
  const conversionRate = context?.conversionRate || 1;
  const navigate = useNavigate();
  const userDetails = useSelector(
    (state: RootState) => state?.user?.user || null
  );
  const [isProcessing, setIsProcessing] = useState(false);

  const setIsLoading = context?.setIsLoading;

  useEffect(() => {
    if (setIsLoading) setIsLoading(true);
    fetchCartDataFromDB();
  }, []);

  const fetchCartDataFromDB = async () => {
    const response = await fetch(SummaryAPI.cartProducts.URL, {
      method: SummaryAPI.cartProducts.method,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    });
    const responseData = await response.json();
    if (responseData.success) {
      setCartItems([...responseData.data.product]);
    } else {
      customToast(responseData.message, "error");
    }
    if (setIsLoading) setIsLoading(false);
  };

  const deleteCartDataFromDB = async (
    productId: string,
    productSize: string
  ) => {
    if (setIsLoading) setIsLoading(true);
    const response = await fetch(SummaryAPI.removeFromCartController.URL, {
      method: SummaryAPI.removeFromCartController.method,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ productId, size: productSize }),
    });
    const responseData = await response.json();
    if (responseData.success) {
      customToast(responseData.message, "success");
      context && context.fetchUserAddToCart();
      setCartItems([...responseData.data.product]);
    } else {
      customToast(responseData.message, "error");
    }
    if (setIsLoading) setIsLoading(false);
  };

  const updateCartProductQuantity = async (
    productId: string,
    quantity: number,
    productSize: string
  ) => {
    if (setIsLoading) setIsLoading(true);
    const response = await fetch(SummaryAPI.updateCartQuantity.URL, {
      method: SummaryAPI.updateCartQuantity.method,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ productId, quantity, size: productSize }),
    });
    const responseData = await response.json();
    if (responseData.success) {
      setCartItems([...responseData.data.product]);
    }
    if (setIsLoading) setIsLoading(false);
  };

  const handleBuy = (item: cartItem) => {
    if (!isProcessing) {
      setIsProcessing(true);
      if (setIsLoading) setIsLoading(true);
      if (userDetails) {
        navigate(`/checkout/${item.id}`, { state: { checkoutItems: [item] } });
      } else {
        customToast("Please login before proceeding for checkout", "error");
        navigate(`/login`);
      }
      setIsProcessing(false);
      if (setIsLoading) setIsLoading(false);
    }
  };

  const handleBuyAll = () => {
    if (!isProcessing) {
      setIsProcessing(true);
      if (setIsLoading) setIsLoading(true);
      if (userDetails) {
        navigate(`/checkout/all`, { state: { checkoutItems: cartItems } });
      } else {
        customToast("Please login before proceeding for checkout", "error");
        navigate(`/login`);
      }
      setIsProcessing(false);
      if (setIsLoading) setIsLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-semibold mb-6 text-center text-customGold">
        Shopping Cart
      </h1>

      {cartItems.length === 0 ? (
        <p className="text-center text-lg text-customGold">
          Your cart is empty.
        </p>
      ) : (
        <div className="space-y-6">
          {cartItems.map((item: cartItem, _index: number) => (
            <div
              key={item.id + _index}
              className="grid md:grid-cols-5 gap-4 p-4 border rounded-lg shadow-md border-gray-200 bg-white"
            >
              {/* Product Image */}
              <div className="w-full flex justify-center p-4">
                <div className="aspect-w-9 aspect-h-13 w-full">
                  <img
                    src={item.imageUrl}
                    alt={item.name}
                    className="object-cover w-full h-full rounded-md"
                  />
                </div>
              </div>

              {/* Product Details */}
              <div className="md:col-span-2 flex flex-col justify-center text-center md:text-left">
                <h2 className="text-xl font-semibold text-customGold">
                  {item.name}
                </h2>
                <p className="text-customGold">
                  Price:{" "}
                  {displayCurrency(
                    Number(item.price) * conversionRate,
                    locale,
                    currencyName
                  )}
                </p>
                <p className="text-customGold">Size: {item.size}</p>
              </div>

              {/* Quantity Control and Total Price */}
              <div className="md:col-span-1 flex flex-col items-center justify-center">
                <div className="flex items-center gap-2">
                  <button
                    className={`px-2 py-1 border rounded ${
                      item.quantity <= 1
                        ? "text-gray-200 border-gray-200 cursor-not-allowed"
                        : "text-customGold border-customGold"
                    }`}
                    onClick={() =>
                      updateCartProductQuantity(
                        item.id,
                        item.quantity - 1,
                        item.size
                      )
                    }
                    disabled={item.quantity <= 1}
                  >
                    <FaMinus />
                  </button>
                  <span className="text-lg font-medium text-customGold">
                    {item.quantity}
                  </span>
                  <button
                    className="px-2 py-1 border border-customGold text-customGold rounded"
                    onClick={() =>
                      updateCartProductQuantity(
                        item.id,
                        item.quantity + 1,
                        item.size
                      )
                    }
                  >
                    <FaPlus />
                  </button>
                </div>
                <p className="mt-2 font-semibold text-customGold">
                  Total:{" "}
                  {displayCurrency(
                    Number(item.price * item.quantity) * conversionRate,
                    locale,
                    currencyName
                  )}
                </p>
              </div>

              {/* Action Buttons */}
              <div className="md:col-span-1 flex flex-col space-y-2 items-center justify-center">
                <button
                  className="w-full border border-red-500 text-red-500 hover:bg-red-500 hover:text-white py-2 rounded"
                  onClick={() => deleteCartDataFromDB(item.id, item.size)}
                >
                  Remove
                </button>
                <button
                  className="w-full border border-green-500 text-green-500 hover:bg-green-500 hover:text-white py-2 rounded"
                  onClick={() => handleBuy(item)}
                  disabled={isProcessing}
                >
                  Buy
                </button>
              </div>
            </div>
          ))}

          {/* Buy All Button */}
          <div className="flex justify-center mt-6">
            <button
              className="bg-black text-customGold px-8 py-3 rounded-lg border-2 border-customGold hover:bg-customGoldHover hover:text-black hover:border-black"
              onClick={handleBuyAll}
              disabled={isProcessing}
            >
              Buy All
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cart;
