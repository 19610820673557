import { useContext } from "react";
import SummaryAPI from "../common";
import { customToast } from "../common/helperFunction";
import Context from "../context";

const useAddToCart = () => {
  const userDetails = useContext(Context);

  const addToCart = async (
    e: React.MouseEvent | React.KeyboardEvent,
    id: string | undefined,
    quantity: number = 1,
    size: string
  ) => {
    e?.stopPropagation();
    e?.preventDefault();

    try {
      const response = await fetch(SummaryAPI.addToCartProduct.URL, {
        method: SummaryAPI.addToCartProduct.method,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ productId: id, quantity, size }),
      });

      const responseData = await response.json();
      // console.log(id, "Product added to cart:", responseData);

      if (responseData.success) {
        customToast(responseData.message, "success");
        userDetails?.fetchUserAddToCart?.(); // Call the context function if it exists
      } else {
        customToast(responseData.message, "error");
      }
    } catch (error) {
      console.error("Error adding product to cart:", error);
      customToast("An error occurred. Please try again.", "error");
    }
  };

  return addToCart;
};

export default useAddToCart;
