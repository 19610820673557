import { toast } from "react-toastify";
import { product } from "../interface/componentInterface";

export const customToast = (msg: string, type: "error" | "success") => {
  if (type === "success") {
    // console.log(msg, "toast popup");
    toast.success(msg, {
      bodyClassName: "text-customGold",
    });
  } else {
    // console.log(msg, "toast popup");
    toast.error(msg, {
      bodyClassName: "text-customGold",
    });
  }
};

export const productCategory = [
  { id: 1, label: "T-Shirt", value: "tShirt" },
  { id: 2, label: "Hoodies", value: "hoodies" },
  { id: 3, label: "Co-ord Set", value: "coordSet" },
  // { id: 4, label: "Frocks", value: "frocks" },
];

export const shuffleArray = (arr: product[]) => {
  const array = [...arr]; // Make a copy of the array to avoid mutating the original
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]]; // Swap elements
  }
  return array;
};

export const localeOptions = [
  { label: "🇮🇳 India", value: "en-IN", currency: "INR" }, // Indian Rupee
  { label: "🇸🇦 Saudi Arabia", value: "ar-SA", currency: "SAR" }, // Saudi Riyal
  {
    label: "🇦🇪 United Arab Emirates",
    value: "ar-AE",
    currency: "AED",
  }, // UAE Dirham
  { label: "🇺🇸 United States", value: "en-US", currency: "USD" }, // US Dollar
  { label: "🇫🇷 France", value: "fr-FR", currency: "EUR" }, // Euro
  { label: "🇩🇪 Germany", value: "de-DE", currency: "EUR" }, // Euro
  { label: "🇸🇬 Singapore", value: "en-SG", currency: "SGD" }, // Singapore Dollar
];

export const getLocaleDetails = (value: string) => {
  const option = localeOptions.find((locale) => locale.value === value);
  return option;
};
